import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBaw36tqmabAZnyDHnGg9dlcMYCOYfLNhI",
  authDomain: "cynorix-gdrive-fileshare.firebaseapp.com",
  databaseURL: "https://cynorix-gdrive-fileshare-default-rtdb.firebaseio.com",
  projectId: "cynorix-gdrive-fileshare",
  storageBucket: "cynorix-gdrive-fileshare.appspot.com",
  messagingSenderId: "36592804947",
  appId: "1:36592804947:web:d04c44d0c76a5d982492a6"
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
