import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Row,
  Col,
  ListGroup,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import "./App.css";

import BannerImage from "./BannerImage";
const firebaseAddress = process.env.REACT_APP_SERVER_URL;

export default function CreateClass(props) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [serviceClassName, setServiceClassName] = useState("");
  const [billingPeriod, setBillingPeriod] = useState("month");
  const [basicRate, setBasicRate] = useState(0);
  const [extraRate, setExtraRate] = useState(0);
  const [extraFileRate, setExtraFileRate] = useState(0);
  const [fileLimit, setFileLimit] = useState(0);
  const [fileMaxAmount, setFileMaxAmount] = useState(0);
  const [currency, setCurrency] = useState("CAD");
  const [hasAccess, setHasAccess] = useState(false);
  const [discountClass, setDiscountClass] = useState(false);

  useEffect(() => {
    // check access before proceeding
    setHasAccess(true);
    if (props.user) {
      if (props.user.userEmail !== "maintenance@cynorix.com") return;
      setHasAccess(true);
    }
  }, [props.user]);

  const navigate = useNavigate();

  const handleCreateClass = async (e) => {
    e.preventDefault();
    try {
      setError("");
      let result = await storeClass();
      if (!result.success) {
        setError(result.error);
        return;
      }

      setSuccess("Class created successfully");
      setTimeout(() => {
        setSuccess("");
        navigate("/maintenance-dashboard");
      }, 1000);
    } catch {
      setError("Failed to create class");
    }
  };

  const validateInput = (value) => {
    if (parseFloat(value)) {
      return true;
    } else {
      return false;
    }
  };

  const currencyFormat = (value) => {
    return Math.floor(parseFloat(value) * 100) / 100;
  };

  const storeClass = async () => {
    // do input validation
    if (!validateInput(extraRate) || currencyFormat(extraRate) * 50 < 1) {
      alert("Extra share rate must be a dollar amount greater than 2 cents.");
      return;
    }
    if (!validateInput(basicRate) || currencyFormat(basicRate) < 1) {
      alert("Rate per month must be a dollar amount greater than 1 dollar.");
      return;
    }
    if (
      !validateInput(extraFileRate) ||
      currencyFormat(extraFileRate) * 50 < 1
    ) {
      alert("Extra file rate must be a dollar amount greater than 2 cents.");
      return;
    }

    const product = {
      name: serviceClassName,
      billing_period: billingPeriod,
      price: currencyFormat(basicRate),
      extra_rate: currencyFormat(extraRate),
      extra_file_rate: currencyFormat(extraFileRate),
      currency: currency,
      file_max_amount: parseInt(fileMaxAmount),
      file_limit: parseInt(fileLimit),
      discountClass: discountClass,
    };
    return await Axios.post(`${firebaseAddress}createProductMaintenance`, {
      product,
    }, {
      headers: {
        Authorization: `Bearer ${props.access_token}`,
      }
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <>
      {hasAccess && (
        <>
          <Card
            style={{
              border: "none",
              boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Card.Body>
              <BannerImage />
              <h4 className="mt-3">
                <b>Create a Class</b>
              </h4>
              <hr className="mt-0" />
              <Row>
                <Col>
                  <Form onSubmit={handleCreateClass}>
                    <Form.Group id="subscription_name" className="mb-3">
                      <Row>
                        <Col
                          xs={12}
                          lg={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                          className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                        >
                          Subscription Name
                        </Col>
                        <Col xs={12} lg={10}>
                          <Form.Control
                            type="text"
                            required
                            onChange={(event) => {
                              event.preventDefault();
                              setServiceClassName(event.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group id="discounted_tiers" className="mb-3">
                      <Row>
                          <Col
                              xs={12}
                              lg={2}
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                              }}
                              className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                          >
                              Discounted Tiers
                          </Col>
                          <Col xs={12} lg={10}>
                              <Form.Check 
                                  type="radio"
                                  id="yes-option"
                                  name="discountedTiersOptions"
                                  label="Yes"
                                  inline
                                  onChange={(event) => {
                                      event.preventDefault();
                                      setDiscountClass(true)
                                    }}
                              />
                              <Form.Check 
                                  type="radio"
                                  id="no-option"
                                  name="discountedTiersOptions"
                                  label="No"
                                  defaultChecked
                                  inline
                                  onChange={(event) => {
                                      event.preventDefault();
                                      setDiscountClass(false)                                  }}
                              />
                          </Col>
                      </Row>
                  </Form.Group>

                    <Form.Group id="billing_period" className="mb-3">
                      <Row>
                        <Col
                          xs={12}
                          lg={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                          className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                        >
                          Billing Period
                        </Col>
                        <Col xs={12} lg={10}>
                          <Form.Select
                            onChange={(event) => {
                              event.preventDefault();
                              setBillingPeriod(event.target.value);
                            }}
                          >
                            <option value={"month"}>1 Month</option>
                            <option value={"year"}>1 Year</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group id="basic_rate" className="mb-3">
                      <Row>
                        <Col
                          xs={12}
                          lg={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                          className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                        >
                          Rate Per {billingPeriod === "month" ? "Month" : "Year"}
                        </Col>
                        <Col xs={12} lg={5}>
                          <Form.Control
                            // min={1}
                            // type="number"
                            onChange={(event) => {
                              event.preventDefault();
                              setBasicRate(event.target.value);
                            }}
                            required
                          />
                        </Col>
                        <Col xs={12} lg={5}>
                          <Form.Select
                            onChange={(event) => {
                              event.preventDefault();
                              setCurrency(event.target.value);
                            }}
                          >
                            <option value={"CAD"}>CAD</option>
                            <option value={"USD"}>USD</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group id="file_restrictions" className="mb-3">
                      <Row>
                        <Col
                          xs={12}
                          lg={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                          className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                        >
                          File Limit
                        </Col>
                        <Col xs={12} lg={10}>
                          <Form.Control
                            min={0}
                            type="number"
                            required
                            onChange={(event) => {
                              event.preventDefault();
                              setFileLimit(event.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group id="file_restrictions" className="mb-3">
                      <Row>
                        <Col
                          xs={12}
                          lg={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                          className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                        >
                          File Max Amount
                        </Col>
                        <Col xs={12} lg={10}>
                          <Form.Control
                            min={0}
                            type="number"
                            required
                            onChange={(event) => {
                              event.preventDefault();
                              setFileMaxAmount(event.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group id="file_restrictions" className="mb-3">
                      <Row>
                        <Col
                          xs={12}
                          lg={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                          className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                        >
                          Extra Share Rate
                        </Col>
                        <Col xs={12} lg={10}>
                          <Form.Control
                            // min={0}
                            // type="number"
                            required
                            onChange={(event) => {
                              event.preventDefault();
                              setExtraRate(event.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group id="file_restrictions" className="mb-3">
                      <Row>
                        <Col
                          xs={12}
                          lg={2}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                          className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                        >
                          Extra File Rate
                        </Col>
                        <Col xs={12} lg={10}>
                          <Form.Control
                            // min={0}
                            // type="number"
                            required
                            onChange={(event) => {
                              event.preventDefault();
                              setExtraFileRate(event.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      {success && <Alert variant="success">{success}</Alert>}
                      {error && <Alert variant="danger">{error}</Alert>}

                      <Button
                        className="mt-3 w-100"
                        disabled={success}
                        type="submit"
                      >
                        Create Class
                      </Button>
                      <Link to="/maintenance-dashboard" className="mt-3 w-100">
                        <Button className="mt-3 w-100">Back</Button>
                      </Link>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
      {!hasAccess && <h4>Access denied.</h4>}
    </>
  );
}
