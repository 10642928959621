import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Table,
  Spinner,
  Row,
  Modal,
  Col,
  Form,
  Alert,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { serverUrl } from "../util/SecureCommunication";
import BannerImage from "./BannerImage";
import FeedbackAlert from "./FeedbackAlert";
import "./ServiceUsageInfo.css";
import InfoButton from "./InfoButton.js";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { getUserFromEmail, getEmailFromId } from "../functions/getUser";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { sendEmail } from "../util/SecureCommunication";
import LoadingButton from "./LoadingButton";
import { verifyPassword } from "../util/SecureCommunication";
import { useCallback } from "react";

library.add(faCircleInfo);

const URL = process.env.REACT_APP_FRONTEND_URL + "login";

const subscriptionPropertyToName = [
  ["Name", "Subscription"],
  ["Price", "Rate"],
  ["Currency", "Currency"],
  ["BillingPeriod", "Billing Period"],
  ["MaxFileCount", "Encryption limit"],
  ["MaxFileShare", "Share Limit"],
  ["ExtraRate", "Rate Past Share Limit"],
  ["ExtraFileRate", "Rate Past Encrypt Limit"],
  ["EmailService", "Email Service"],
  ["SMSService", "SMS Service"],
];

const usagePropertyToName = [
  ["firstName", "First Name"],
  ["lastName", "Last Name"],
  ["email", "Email"],
  ["file_shared_alreadyused", "Files Shared"],
  ["file_shared_allowtouse", "Sharing Quota"],
  ["file_shared_amountremain", "Remaining Shares"],
  ["encryption_alreadyused", "Files Encrypted"],
  ["encryption_allowtouse", "Encryption Quota"],
  ["encryption_amountremain", "Remaining Encrypts"],
];

const usagePropertyToNameSponsored = [
  ["firstName", "First Name"],
  ["lastName", "Last Name"],
  ["email", "Email"],
  ["file_shared_alreadyused", "Files Shared"],
  ["file_shared_amountremain", "Remaining Shares"],
  ["encryption_alreadyused", "Files Encrypted"],
  ["encryption_amountremain", "Remaining Encrypts"],
];

const usagePropertyToNameSponsoring = [
  ["firstName", "First Name"],
  ["lastName", "Last Name"],
  ["email", "Email"],
  ["file_shared_alreadyused", "Files Shared"],
  ["file_shared_allowtouse", "Sharing Quota"],
  ["file_shared_amountremain", "Remaining Shares"],
  ["encryption_alreadyused", "Files Encrypted"],
  ["encryption_allowtouse", "Encryption Quota"],
  ["encryption_amountremain", "Remaining Encrypts"],
  ["remove_sponsorship", "Remove Sponsorship"],
];

const usagePropertyToNameEmployeeSponsoring = [
  ["firstName", "First Name"],
  ["lastName", "Last Name"],
  ["employee_email", "Email"],
  ["employee_shared", "Files Shared"],
  ["file_shared_allowtouse", "Sharing Quota"],
  ["file_shared_amountremain", "Remaining Shares"],
  ["employee_encrypted", "Files Encrypted"],
  ["encryption_allowtouse", "Encryption Quota"],
  ["encryption_amountremain", "Remaining Encrypts"],
  ["removed", "Remove Sponsorship"],
];

export default function ServiceUsageInfo(props) {
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [usageInfo, setUsageInfo] = useState({});
  const [totalInfo, setTotalInfo] = useState({});
  const [isSponsored, setIsSponsored] = useState(false);
  const [isSponsoring, setIsSponsoring] = useState(false);
  const [sponsoredEmployees, setSponsoredEmployees] = useState([]);
  const [removed, setRemoved] = useState("");
  const [sponsorEmail, setSponsorEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [showRmSponsorshipModal, setShowRmSponsorshipModal] = useState(false);
  const [employeeToRemove, setEmployeeToRemove] = useState(null);
  const [error, setError] = useState("");

  const [subInfoFeedback, setSubInfoFeedback] = useState(null);
  const [usageInfoFeedback, setUsageInfoFeedback] = useState(null);

  const navigate = useNavigate();

  const codeRef = useRef();

  // get the data for subscription and usage on app mount, checking if user is sponsored
  useEffect(() => {
    async function getInfo() {
      let data = await getSponsored();
      // console.log(data);
      if (Object.hasOwn(data, "sponsor_id")) {
        // console.log("sponsored");
        // is being sponsored by someone
        setIsSponsored(true);
        setSponsorEmail(await getEmailFromId(props.user.id, props.access_token, data.sponsor_id));
        // get subscription info from sponsor
        getSubscriptionInfo(setSubInfoFeedback, data.sponsor_id);
        // get usage info
        getUsageInfo(setSubInfoFeedback, data.sponsor_id);
      } else if (Object.hasOwn(data, "employee_service")) {
        // console.log("sponsoring");
        // is sponsoring someone else
        setIsSponsoring(true);
        // get subscription info
        getSubscriptionInfo(setSubInfoFeedback);
        // get usage info
        getUsageInfo(setUsageInfoFeedback, null, data.employee_service);
      } else {
        // console.log("in");
        // get subscription info
        getSubscriptionInfo(setSubInfoFeedback);
        // get usage info
        getUsageInfo(setUsageInfoFeedback);
      }
    }
    getInfo();
  }, []);

  const handleRemoveSponsorship = (employee) => {
    setEmployeeToRemove(employee);
    setShowRmSponsorshipModal(true);
  };

  const handleClose = () => {
    setShowRmSponsorshipModal(false);
  };

  async function getSponsored() {
    return await axios
      .post(`${serverUrl}getUserSponsorship`, {
        user_id: props.user.id,
      }, {
        headers: {
          Authorization: `Bearer ${props.access_token}`,
        }
      })
      .then((res) => {
        const data = res.data.data;
        if (data) {
          return data;
        } else {
          return null;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  function getSubscriptionInfo(pushFeedback = () => {}, sponsorId) {
    pushFeedback({
      variant: "info",
      loading: true,
      message: "Getting your current subscription data...",
    });
    axios
      .post(`${serverUrl}getCurrentProductInfo`, {
        userId: props.user.id,
        sponsorId: sponsorId ? sponsorId : props.user.id,
      }, {
        headers: {
          Authorization: `Bearer ${props.access_token}`,
        }
      })
      .then((res) => {
        // console.log(res.data.data);
        const data = res.data.data;
        if (data.ExtraRate !== "N/A" && data.ExtraFileRate !== "N/A") {
          let stringPriceShare = data.ExtraRate.toFixed(2);
          data.ExtraRate = "$" + stringPriceShare;
          let stringPriceEncrypt = data.ExtraFileRate.toFixed(2);
          data.ExtraFileRate = "$" + stringPriceEncrypt;
        }
        setSubscriptionInfo({
          EmailService: "yes",
          SMSService: "yes",
          ...data,
        });
        pushFeedback(null);
      })
      .catch((err) => {
        console.log(err);
        pushFeedback({
          variant: "danger",
          message: "Error occurred while getting subscription information.",
        });
      });
  }

  const handleRemove = useCallback(async (e) => {
    e.preventDefault();
    try {
      const userEmail = props.user.email;
      const userData = (await getUserFromEmail(userEmail, props.access_token, props.user.id)).userData;
      const employeeData = (
        await getUserFromEmail(employeeToRemove.employee_email, props.access_token, props.user.id)
      ).userData;
      const employeeId = (
        await getUserFromEmail(employeeToRemove.employee_email, props.access_token, props.user.id)
      ).id;
      setLoading(true);
      try {
        const isPwdVerified = await verifyPassword(
          props.user.id,
          codeRef.current.value,
          props.access_token,
          () => {
            navigate("/unlock");
          }
        );
        if (isPwdVerified) {
          //Remove Sponsorship
          await removeSponsorship(
            employeeData,
            userData,
            employeeId,
            props.user.id
          );
          // send email to notify employee that the request is accepted
          await sendRemoveEmail(employeeData.email, userEmail);
        } else {
          setError("Incorrect Password");
        }
      } catch (e) {
        setError("Something went wrong while verifying your password.");
      }
    } catch (error) {
      console.log(error);
      alert("Error Removing Sponsorship");
    }
  }, [props.user, employeeToRemove])

  async function sendRemoveEmail(employeeEmail, sponsorEmail) {
    //Sender is the Sponsor, Receiver is the employee
    try {
      const sponsor = getUserFromEmail(sponsorEmail, props.access_token, props.user.id);
      const body = await composeRemoveEmail(sponsorEmail);

      sendEmail(
        "Cynorix (Google Drive) Sponsorship Removal Notification",
        body,
        employeeEmail,
        sponsor
      )
        .then((res) => {
          setSubInfoFeedback({
            variant: "success",
            message: `Sponsorship has been removed!`,
          });
        })
        .catch((err) => {
          setSubInfoFeedback({
            variant: "danger",
            message: `An error occurred while sending the email notification.`,
          });
        });
    } catch (e) {
      setSubInfoFeedback({
        variant: "danger",
        message: `An error occurred while sending the email notification.`,
      });
    }
  }

  const composeRemoveEmail = async (senderEmail) => {
    try {
      return (
        "<div><p>Dear user,</p><p>The user with email address " +
        senderEmail +
        " has canceled your sponsorship. Please click " +
        "<a href=" +
        '"' +
        URL +
        '"' +
        ">here</a>" +
        " to login and continue. Otherwise, please ignore this email.</p>" +
        "<p>Thanks for using Cynorix Secure File Sharing</p>" +
        "<p>Please visit www.cynorix.com for other products.</p></div>"
      );
    } catch (e) {
      console.log(e);
      throw new Error("Error getting Sponsor Data");
    }
  };

  const removeSponsorship = async (
    employeeData,
    sponsorData,
    employeeId,
    sponsorId
  ) => {
    await axios
      .post(`${serverUrl}removeSponsorship`, {
        employeeData: employeeData,
        sponsorData: sponsorData,
        employeeId: employeeId,
        sponsorId: sponsorId,
      }, {
        headers: {
          Authorization: `Bearer ${props.access_token}`,
        }
      })
      .then(() => {
        setRemoved("Sponsorship terminated.");
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        throw new Error("Failed to Remove Sponsorship");
      });
  };

  async function getUsageInfo(
    pushFeedback = () => {},
    sponsorId = null,
    employeeService = null
  ) {
    pushFeedback({
      variant: "info",
      loading: true,
      message: "Getting your current usage data...",
    });
    // await axios
    //   .post(`${serverUrl}getUserSponsorship`, { user_id: props.user.id })
    //   .then(async (res) => {
    //     const data = res.data.data;
    //     // if user is sponsored
    if (sponsorId) {
      let obj;
      const nameSplit = props.user.name.split(" ");
      obj = {
        firstName: nameSplit[0],
        lastName: nameSplit[nameSplit.length - 1],
        email: props.user.email,
      };
      await axios
        .post(`${serverUrl}getServiceUsage`, { userId: props.user.id }, {
          headers: {
            Authorization: `Bearer ${props.access_token}`,
          }
        })
        .then(async (res) => {
          // console.log(res);
          const data = res.data.data;
          obj = Object.assign(
            {
              file_shared_amountremain: data.file_shared_amountremain,
              encryption_amountremain: data.encryption_amountremain,
            },
            obj
          );
          const sponsorEmail = await getEmailFromId(props.user.id, props.access_token, sponsorId);
          const sponsorData = (await getUserFromEmail(sponsorEmail, props.access_token, props.user.id)).userData;
          obj = Object.assign(
            {
              file_shared_alreadyused:
                sponsorData.service.employee_service[props.user.id]
                  .employee_shared,
              encryption_alreadyused:
                sponsorData.service.employee_service[props.user.id]
                  .employee_encrypted,
            },
            obj
          );
          setUsageInfo(obj);
          pushFeedback(null);
        })
        .catch((err) => {
          pushFeedback({
            variant: "danger",
            message: "Error occurred while getting sponsor usage information.",
          });
        });
    } else if (employeeService) {
      // console.log(employeeService);
      // get the sponsor's information
      // if sponsored, go through the list of employees and get encryption and sharing data
      let employeeInfoArray = [];
      let employeeEncryptions = 0;
      let employeeShares = 0;
      for (const employee in employeeService) {
        employeeInfoArray.push(employeeService[employee]);
        // console.log(employeeService[employee].employee_encrypted);
        employeeEncryptions += employeeService[employee].employee_encrypted;
        employeeShares += employeeService[employee].employee_shared;
      }
      setSponsoredEmployees(employeeInfoArray);
      // console.log(employeeInfoArray);

      await axios
        .post(`${serverUrl}getServiceUsage`, { userId: props.user.id }, {
          headers: {
            Authorization: `Bearer ${props.access_token}`,
          }
        })
        .then((res) => {
          let data = res.data.data;
          const nameSplit = props.user.name.split(" ");
          let totalEncryptions = data.encryption_alreadyused;
          data.encryption_alreadyused -= employeeEncryptions;
          // console.log(data.encryption_alreadyused);
          setUsageInfo({
            firstName: nameSplit[0],
            lastName: nameSplit[nameSplit.length - 1],
            email: props.user.email,
            ...data,
          });
          // console.log(data);
          data.encryption_alreadyused = totalEncryptions;
          data.file_shared_alreadyused += employeeShares;

          setTotalInfo({
            firstName: "Total",
            ...data,
          });

          pushFeedback(null);
        })
        .catch((err) => {
          pushFeedback({
            variant: "danger",
            message: "Error occurred while getting usage information.",
          });
        });
    } else {
      await axios
        .post(`${serverUrl}getServiceUsage`, { userId: props.user.id }, {
          headers: {
            Authorization: `Bearer ${props.access_token}`,
          }
        })
        .then((res) => {
          const data = res.data.data;
          const nameSplit = props.user.name.split(" ");

          setUsageInfo({
            firstName: nameSplit[0],
            lastName: nameSplit[nameSplit.length - 1],
            email: props.user.email,
            ...data,
          });
          pushFeedback(null);
        })
        .catch((err) => {
          pushFeedback({
            variant: "danger",
            message: "Error occurred while getting usage information.",
          });
        });
    }
    // })
  }

  return (
    <Card
      body
      style={{
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      {/* TODO: Change the banner image */}
      <BannerImage />

      <h4 className="mt-3">
        <Row>
          <Col>
            <b>Service Usage Information</b>
          </Col>
          <Col xs="auto">
            <InfoButton header="Service Usage">
              <p className="mb-1">
                The service usage page lists all of a user's subscription
                information. Under <strong>My Subscription</strong>, the details
                of their subscription are listed. Under{" "}
                <strong>My Usage</strong>, the amount of shares and encryptions
                a user has available and have used are listed.
              </p>
            </InfoButton>
          </Col>
        </Row>
      </h4>

      <hr className="mt-0" />
      <h5>
        <b>My Subscription</b>
      </h5>
      <Card
        className="p-3 pb-0"
        style={{
          width: "100%",
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Table responsive>
          <thead style={{ color: "#424242" }}>
            <tr>
              {subscriptionPropertyToName.map(([prop, name]) => (
                <th key={name}>{name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {subscriptionPropertyToName.map(([prop, name]) => (
                <td key={prop}>
                  {subscriptionInfo[prop] === undefined ? (
                    <Spinner size="sm" />
                  ) : (
                    subscriptionInfo[prop]
                  )}
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
        <FeedbackAlert feedback={subInfoFeedback} />
      </Card>
      <hr />
      <h5>
        <b>My Usage</b>
      </h5>
      {isSponsored && (
        <div className="pb-2">
          You are currently being sponsored by <b>{sponsorEmail}</b>
        </div>
      )}
      <Card
        className="p-3 pb-0"
        style={{
          width: "100%",
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Table responsive>
          <thead style={{ color: "#424242" }}>
            <tr>
              {!isSponsored &&
                !isSponsoring &&
                usagePropertyToName.map(([prop, name]) => (
                  //not sponsoring or being sponsored
                  <th key={name}>{name}</th>
                ))}
              {isSponsored &&
                usagePropertyToNameSponsored.map(([prop, name]) => (
                  //is being sponsored
                  <th key={name}>{name}</th>
                ))}
              {isSponsoring &&
                usagePropertyToNameSponsoring.map(([prop, name]) => (
                  //is a sponsor
                  <th key={name}>{name}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {!isSponsoring ? (
              <tr>
                {!isSponsored &&
                  !isSponsoring &&
                  usagePropertyToName.map(([prop, name]) => (
                    <td key={prop}>
                      {usageInfo[prop] === undefined ? (
                        <Spinner size="sm" />
                      ) : (
                        usageInfo[prop]
                      )}
                    </td>
                  ))}
                {isSponsored &&
                  usagePropertyToNameSponsored.map(([prop, name]) => (
                    <td key={prop}>
                      {usageInfo[prop] === undefined ? (
                        <Spinner size="sm" />
                      ) : (
                        usageInfo[prop]
                      )}
                    </td>
                  ))}
              </tr>
            ) : (
              <>
                <tr>
                  {/* {console.log(usageInfo)} */}
                  {usagePropertyToNameSponsoring.map(([prop, name]) => (
                    <td key={prop}>
                      {usageInfo[prop] !== undefined && usageInfo[prop]}
                    </td>
                  ))}
                </tr>
                {sponsoredEmployees.map((employee) => (
                  <tr key={employee}>
                    {usagePropertyToNameEmployeeSponsoring.map(
                      ([prop, name]) => (
                        <td key={prop}>
                          {employee[prop] !== undefined &&
                            prop !== "removed" &&
                            employee[prop]}
                          {prop == "removed" && employee[prop] && "Unsponsored"}
                          {prop == "removed" && !employee[prop] && (
                            <LoadingButton
                              size="sm"
                              loading={removeLoading}
                              onClick={() => {
                                handleRemoveSponsorship(employee);
                              }}
                            >
                              Unsponsor
                            </LoadingButton>
                          )}
                        </td>
                      )
                    )}
                  </tr>
                ))}
                <tr style={{ borderTop: "1.5px solid #000" }}>
                  {usagePropertyToNameSponsoring.map(([prop, name]) => (
                    <td key={prop} style={{ fontWeight: "bold" }}>
                      {totalInfo[prop] !== undefined && totalInfo[prop]}
                    </td>
                  ))}
                </tr>
              </>
            )}
          </tbody>
        </Table>
        <br></br>
        <p className="flex ">
          <strong>Note: </strong>
          If you are running out of Remaining Encrypts, you can delete files
          from your <a href="/sheets">file table</a> to get more encrypts.
        </p>
        <FeedbackAlert feedback={usageInfoFeedback} />
        <Modal show={showRmSponsorshipModal} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Please enter your password</Modal.Title>
          </Modal.Header>
            <Form onSubmit={handleRemove}>
              <Modal.Body>
                  <Form.Group>
                    <Form.Label>
                      Please enter your password to unsponsor this user.
                    </Form.Label>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {removed && <Alert variant="success">{removed}</Alert>}
                    <Form.Control type="password" ref={codeRef} required />
                  </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <LoadingButton onClick={handleClose}>Cancel</LoadingButton>
                <LoadingButton type="submit">
                  Remove Sponsorship
                </LoadingButton>
              </Modal.Footer>
            </Form>
        </Modal>
      </Card>
    </Card>
  );
}
