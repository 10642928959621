import React, { Fragment, useState, useEffect } from "react";
import SecurityQuestionCheck from "./SecurityQuestionCheck";
import { Row, Col, Button, Form, Modal, Container } from "react-bootstrap";
import ManageAccountOptsPage from "./ManageAccountOptsPage";
import background from "../../assets/images/background-white.png";
import { formCheck, generateVerification } from "../../functions/encoding";
import axios from "axios";
import FeedbackAlert from "../FeedbackAlert";
import {
  getAuth,
  deleteUser,
  reauthenticateWithCredential,
  GoogleAuthProvider,
} from "firebase/auth";
import "./RemoveAccount.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PasswordWrapper from "../PasswordWrapper";
library.add(faEye, faEyeSlash);

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function RemoveAccount(props) {
  const [formValue, setFormValue] = useState({
    answers: Array(5).fill(""),
    password: "",
    code: "",
  });
  const [otpFormFeedback, setOtpFormFeedback] = useState(null);

  const onChangeAnswer = (index, value) => {
    let newAnswer = formValue.answers;
    newAnswer[index] = value;
    setFormValue({ ...formValue, answers: newAnswer });
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    let data = {
      passCheck: formCheck(formValue.password + props.user.id),
      answersCheck: generateVerification(formValue.answers.join("")),
      userId: props.user.id,
    };

    setOtpFormFeedback({
      loading: true,
      variant: "info",
      message: `Deleting your account...`,
    });

    axios
      .post(SERVER_URL + "removeAccount", data,{
        headers: {
          Authorization: `Bearer ${props.access_token}`,
        }
      })
      .then(async (res) => {
        // ** Remove user from Firebase Auth
        // reauthenticate
        let authResponse = window.gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .getAuthResponse();
        const currentUser = getAuth().currentUser;
        const credential = GoogleAuthProvider.credential(
          authResponse.id_token,
          authResponse.access_token
        );

        try {
          await reauthenticateWithCredential(currentUser, credential);
          await deleteUser(currentUser);
          setOtpFormFeedback({
            loading: true,
            variant: "secondary",
            message: `Account fully removed. We are sorry to see you go.`,
          });
          props.signOut();
          alert("Your account has been removed.");
          window.location.replace("/home");
        } catch (e) {
          alert(
            "There was an error while removing your account. Please contact us."
          );
          props.signOut();
        }
      })
      .catch((error) => {
        if (error.response.data.errormsg === "Wrong Answers") {
          alert("Please Recheck your questions");
          setOtpFormFeedback({
            variant: "warning",
            message: `Wrong Answers`,
          });
        } else {
          setOtpFormFeedback({
            variant: "warning",
            message: `Something went wrong. Please try again.`,
          });
        }
      });
  };

  const deleteBody = (
    <Fragment>
      <Form
        className="align-items-center"
        style={{ width: "100%", display: "contents" }}
        onSubmit={handleCodeSubmit}
      >
        <SecurityQuestionCheck
          answers={formValue.answers}
          onChangeAnswer={onChangeAnswer}
          user={props.user}
        />
        <Button
          disabled={otpFormFeedback && otpFormFeedback.loading}
          type="submit"
          className="mt-3 w-100"
        >
          Submit
        </Button>
        <FeedbackAlert feedback={otpFormFeedback} className="mt-3 mb-0" />
      </Form>
    </Fragment>
  );

  return (
    <>
      <ManageAccountOptsPage
        title={"Remove Account"}
        background={background}
        body={
          <PasswordWrapper
            user={props.user}
            access_token={props.access_token}
            requiresOTP
            setPassword={(password) => {
              setFormValue({ ...formValue, password });
            }}
          >
            {deleteBody}
          </PasswordWrapper>
        }
      />
    </>
  );
}
