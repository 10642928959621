import React, { Fragment, useState, useEffect } from "react";
import SecurityQuestionCheck from "./SecurityQuestionCheck";
import { Row, Col, Button, Form } from "react-bootstrap";
import background from "../../assets/images/background-white.png";
import ManageAccountOptsPage from "./ManageAccountOptsPage";
import PhoneCountryCodeDropdown from "../PhoneCountryCodeDropdown";
import { formCheck, generateVerification } from "../../functions/encoding";
import { verifyPassword } from "../../util/SecureCommunication";
import { useNavigate } from "react-router-dom";
import FeedbackAlert from "../FeedbackAlert";
import axios from "axios";
import "./ChangePhoneNumber.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
library.add(faEye, faEyeSlash);

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ChangePhoneNumber(props) {
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    answers: Array(5).fill(""),
    password: "",
    phoneNumber: "",
    code: "",
  });

  const [countryCode, setCountryCode] = useState(1);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [newTwilioId, setNewTwilioId] = useState();
  const [verified, setVerified] = useState();
  const [feedback, setFeedback] = useState(null);
  const [otpFeedback, setOtpFeedback] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [displayTime, setDisplayTime] = useState("");
  const [SMSsent, setSMSsent] = useState(false);
  const [sentOnce, setSentOnce] = useState(false);
  const [numberNotTyped, setNumberNotTyped] = useState(true);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const onChangeAnswer = (index, value) => {
    let newAnswer = formValue.answers;
    newAnswer[index] = value;
    setFormValue({ ...formValue, answers: newAnswer });
  };

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const sendCode = () => {
    setFeedback({
      loading: true,
      message: "Sending OTP",
      variant: "info",
    });
    let data = {
      phoneNumber: formValue.phoneNumber,
      countryCode: countryCode + "",
      userEmail: props.user.email,
    };
    axios
      .post(SERVER_URL + "requestTwilioRegistration", data, {
        headers: {
          Authorization: `Bearer ${props.access_token}`,
        }
      })
      .then((res) => {
        setNewTwilioId(res.data.data.twilioID);
        setOtpFeedback({
          loading: false,
          message: `OTP Sent, time remaining: ${displayTime}`,
          variant: "info",
        });
        setFeedback(null);
        setSentOnce(true);
        setSMSsent(true);
        setTimeLeft(185);
      })
      .catch((error) => {
        console.error("onRejected function called: " + error);
        alert("Wrong Phone Number!");
        setFeedback({
          loading: false,
          message: `An Error Occured`,
          variant: "danger",
        });
      });
  };

  useEffect(() => {
    let timerId;

    if (SMSsent) {
      timerId = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [SMSsent]);

  useEffect(() => {
    if (timeLeft < 0 && SMSsent) {
      setSMSsent(false);
      setTimeLeft(0);
      setFeedback({
        loading: false,
        message: `OTP Expired, please resend code to continue`,
        variant: "warning",
      });
    } else if (timeLeft >= 0 && SMSsent) {
      setDisplayTime(
        Math.floor(timeLeft / 60) +
          ":" +
          (timeLeft % 60 < 10 ? "0" : "") +
          (timeLeft % 60)
      );
    }
  }, [timeLeft, SMSsent]);

  useEffect(() => {
    if (SMSsent) {
      setOtpFeedback({
        loading: false,
        message: `OTP Sent, time remaining: ${displayTime}`,
        variant: "info",
      });
    }
  }, [displayTime, SMSsent]);

  const onSubmit = (e) => {
    e.preventDefault();
    setFeedback({
      loading: true,
      message: "Verifying password",
      variant: "info",
    });
    verifyPassword(props.user.id, formValue.password, props.access_token, () => {
      navigate("/unlock");
    })
      .then((res) => {
        if (!res) {
          setFeedback({
            message: "Incorrect password",
            variant: "danger",
          });
          return;
        } else {
          verifyNewSMS();
        }
      })
      .catch((err) => {
        if (err === "Account is locked.") {
          alert(
            "Your account is locked, please proceed to the unlock account page"
          );
          return;
        }
      });
  };

  const verifyNewSMS = () => {
    setFeedback({
      loading: true,
      message: "Verifying OTP",
      variant: "info",
    });
    axios
      .post(SERVER_URL + "verifyNewPhoneNumber", {
        userId: props.user.id,
        newTwilioId: newTwilioId + "",
        otp: formValue.code,
      }, {
        headers: {
          Authorization: `Bearer ${props.access_token}`,
        }
      })
      .then((res) => {
        if (res.status === 200) {
          setFeedback(null);
          setVerified(true);
        } else {
          setFeedback({
            message: "Something went wrong.",
            variant: "danger",
          });
        }
      })
      .catch((error) => {
        if (error.response.data.status === 500) {
          setFeedback({
            message: "Wrong OTP",
            variant: "danger",
          });
        } else {
          setFeedback({
            message: "Something went wrong.",
            variant: "danger",
          });
        }
      });
  };

  const handleCodeSubmit = () => {
    let data = {
      passCheck: formCheck(formValue.password + props.user.id),
      answersCheck: generateVerification(formValue.answers.join("")),
      newTwilioId: newTwilioId + "",
      userId: props.user.id,
    };

    axios
      .post(SERVER_URL + "changePhoneNumber", data, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${props.access_token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          alert("Phone number was changed successfully!");
          navigate("/new");
        } else alert("Oops, something went wrong!");
      })
      .catch((error) => {
        if (
          error.response.data.errormsg === "Error: Question verification failed"
        ) {
          alert("Please recheck your answers");
        }
      });
  };

  const verifyBody = (
    <Fragment>
      <Form
        className="align-items-center"
        style={{ width: "100%", display: "contents" }}
      >
        <Form.Group className="mt-3">
          <Row className="mb-2">
            <Col
              xs={12}
              lg={2}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
              className={`mb-2 mb-lg-0 
                            justify-content-center justify-content-lg-end`}
            >
              Password
            </Col>
            <Col xs={12} lg={10}>
              <div className="pass-wrapper">
                <input
                  className="form-control"
                  type={passwordShown ? "text" : "password"}
                  autoComplete="new-password"
                  value={formValue.password}
                  name="password"
                  onChange={onChange}
                />
                <i className="test">
                  <FontAwesomeIcon
                    icon={passwordShown ? "eye-slash" : "eye"}
                    onClick={togglePassword}
                  />
                </i>
              </div>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="register-phone-number">
          <Row className="mb-2">
            <Col
              xs={12}
              lg={2}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
              className={`mb-2 mb-lg-0 
                                justify-content-center justify-content-lg-end`}
            >
              New Phone Number
            </Col>
            <Col xs={12} lg={10}>
              <PhoneCountryCodeDropdown setCountryCode={setCountryCode}>
                <Form.Control
                  type="tel"
                  name="phoneNumber"
                  pattern={"[0-9]*"}
                  placeholder="##########"
                  value={formValue.phoneNumber}
                  onChange={(e) => {
                    onChange(e);
                    if (e.target.value !== "") {
                      setNumberNotTyped(false);
                    } else {
                      setNumberNotTyped(true);
                    }
                    // check if the key pressed is a number.
                    if (e.target.value.search(/[^0-9]/) !== -1) {
                      setPhoneErrorMsg(
                        "Please enter a phone number with numbers only."
                      );
                      setNumberNotTyped(true);
                    } else {
                      setPhoneErrorMsg("");
                    }
                  }}
                  isInvalid={phoneErrorMsg !== ""}
                />
                <Form.Control.Feedback type="invalid">
                  {phoneErrorMsg}
                </Form.Control.Feedback>
              </PhoneCountryCodeDropdown>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mt-3">
          <Row className="mb-2">
            <Col
              xs={12}
              lg={2}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
              className={`mb-2 mb-lg-0 
                            justify-content-center justify-content-lg-end`}
            >
              SMS Confirmation
            </Col>
            <Col xs={12} lg={8}>
              <Form.Control
                value={formValue.code}
                onChange={(event) => {
                  setFormValue({ ...formValue, code: event.target.value });
                }}
              />
            </Col>
            <Col xs={12} lg={2}>
              <Button
                onClick={() => {
                  sendCode();
                }}
                className="w-100"
                disabled={SMSsent || numberNotTyped}
                variant="danger"
                style={{ backgroundColor: "#800000", border: "none" }}
              >
                {sentOnce ? "Resend" : "Send Code"}
              </Button>
            </Col>
          </Row>
        </Form.Group>
        <FeedbackAlert feedback={otpFeedback} className="mt-3 mb-0" />
        <Button
          onClick={onSubmit}
          disabled={!SMSsent || (feedback && feedback.loading)}
          type="submit"
          className="mt-3 w-100"
          variant="danger"
          style={{ backgroundColor: "#800000", border: "none" }}
        >
          Submit
        </Button>
        <FeedbackAlert feedback={feedback} className="mt-3 mb-0" />
      </Form>
    </Fragment>
  );

  const changeBody = (
    <Fragment>
      <Form
        className="align-items-center"
        style={{ width: "100%", display: "contents" }}
      >
        <SecurityQuestionCheck
          answers={formValue.answers}
          onChangeAnswer={onChangeAnswer}
          user={props.user}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleCodeSubmit();
          }}
          type="submit"
          className="mt-3 w-100"
          variant="danger"
          style={{ backgroundColor: "#800000", border: "none" }}
        >
          Submit
        </Button>
      </Form>
    </Fragment>
  );

  return (
    <ManageAccountOptsPage
      title={verified ? "Please provide answers" : "Change Phone Number"}
      background={background}
      body={verified ? changeBody : verifyBody}
    />
  );
}
