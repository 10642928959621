import React, { Fragment, useState, useEffect } from "react";
import SecurityQuestionCheck from "./SecurityQuestionCheck";
import { Button, Col, Form, Row } from "react-bootstrap";
import ManageAccountOptsPage from "./ManageAccountOptsPage";
import background from "../../assets/images/background-white.png";
import axios from "axios";
import CryptoJS from "crypto-js";
import TextModal from "./TextModal";
import { useNavigate } from "react-router-dom";
import FeedbackAlert from "../FeedbackAlert";
import LoadingButton from "../LoadingButton";

export default function RecoverPassword(props) {
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    answers: Array(5).fill(""),
    code: "",
  });
  const [sampleModalOpened, setSampleModalOpened] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [heading, setHeading] = useState("");
  const [verified, setVerified] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [otpFeedback, setOtpFeedback] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [displayTime, setDisplayTime] = useState("");
  const [SMSsent, setSMSsent] = useState(false);
  const [sentOnce, setSentOnce] = useState(false);
  const firebaseAddress = process.env.REACT_APP_SERVER_URL;
  const AESLOOPS = 16384;

  const onChangeAnswer = (index, value) => {
    let newAnswer = formValue.answers;
    newAnswer[index] = value;
    setFormValue({ answers: newAnswer, code: formValue.code });
  };

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const uint8ToUint8Array = (str) => {
    let output = [];
    for (const letter of str) {
      output.push(letter.charCodeAt(0));
    }
    return output;
  };

  const uint8ArrayToUint8 = (array) => {
    var output = "";
    for (const element of array) {
      output += String.fromCharCode(element);
    }
    return output;
  };

  const strToBin = (str) => {
    var output = "";
    for (const element of str) {
      output += element.charCodeAt(0).toString(2).padStart(8, "0");
    }
    return output;
  };

  const reverseBits = (str) => {
    var bits = strToBin(str).split("").reverse().join("");
    var output = "";
    for (var i = 0; i < bits.length / 8; i++) {
      output += String.fromCharCode(
        parseInt(bits.substring(i * 8, (i + 1) * 8), 2)
      );
    }
    return output;
  };

  const XOR = (a, b) => {
    var result = new Uint8Array(a.length);
    for (let i = 0; i < a.length; i++) {
      result[i] = a[i] ^ b[i];
    }
    return result;
  };

  const generateKey = (answers) => {
    // Split into vectors of 512 bits (64 characters of 8 bits each)
    var AnswersVectors = answers.match(/.{1,64}/g);

    // Pad last vector with zeros
    AnswersVectors[AnswersVectors.length - 1] = AnswersVectors[
      AnswersVectors.length - 1
    ].padStart(64, "\u0000");

    // Convert strings to Uint8 Arrays to xor
    var Uint8ArrayAnswers = [];
    for (const element of AnswersVectors) {
      Uint8ArrayAnswers.push(uint8ToUint8Array(element));
    }

    // Xor all the vectors
    var xorResult = Uint8ArrayAnswers[0];
    for (var i = 1; i < Uint8ArrayAnswers.length; i++) {
      xorResult = XOR(xorResult, Uint8ArrayAnswers[i]);
    }

    // Convert to Uint8 String to convert to word array
    var xorResultUint8 = uint8ArrayToUint8(xorResult);

    var vector = xorResultUint8;
    var iv = CryptoJS.enc.Base64.parse("9De0DgMTCDFGNokdEEial");

    for (i = 0; i < AESLOOPS; i++) {
      // Create word array for the message and key
      var wordArray = CryptoJS.enc.Latin1.parse(reverseBits(vector));
      var key = CryptoJS.enc.Latin1.parse(vector);

      // Encrypt using AES-256-CBC
      // Only take the first 128 digits to get 512 bits
      vector = CryptoJS.AES.encrypt(wordArray, key, { iv: iv })
        .ciphertext.toString()
        .substring(0, 128);
    }

    // vector is 512 bits and is currently in hex
    return vector;
  };

  const generateVerification = (answers) => {
    // Get first 32 bits of answer encrypted by SHA256 to be used for verification
    // The verification is 8 characters of hex
    return CryptoJS.enc.Hex.stringify(CryptoJS.SHA256(answers)).substring(0, 8);
  };

  const sendTwilioSMS = () => {
    setFeedback({
      loading: true,
      message: "Sending OTP",
      variant: "info",
    });

    axios
      .post(`${firebaseAddress}requestSMSOTP`, {
        userId: props.user.id,
      }, {
        headers: {
          Authorization: `Bearer ${props.access_token}`,
        }
      })
      .then((res) => {
        setOtpFeedback({
          loading: false,
          message: `OTP Sent, time remaining: ${displayTime}`,
          variant: "info",
        });
        setFeedback(null);
        setSentOnce(true);
        setSMSsent(true);
        setTimeLeft(185);
      })
      .catch((err) => {
        setFeedback({
          loading: false,
          message: `An Error Occured`,
          variant: "danger",
        });
      });
  };

  useEffect(() => {
    let timerId;

    if (SMSsent) {
      timerId = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [SMSsent]);

  useEffect(() => {
    if (timeLeft < 0 && SMSsent) {
      setSMSsent(false);
      setTimeLeft(0);
      setOtpFeedback({
        loading: false,
        message: `OTP Expired, please resend code to continue`,
        variant: "warning",
      });
    } else if (timeLeft >= 0 && SMSsent) {
      setDisplayTime(
        Math.floor(timeLeft / 60) +
        ":" +
        (timeLeft % 60 < 10 ? "0" : "") +
        (timeLeft % 60)
      );
    }
  }, [timeLeft, SMSsent]);

  useEffect(() => {
    if (SMSsent) {
      setOtpFeedback({
        loading: false,
        message: `OTP Sent, time remaining: ${displayTime}`,
        variant: "info",
      });
    }
  }, [displayTime, SMSsent]);

  const verifySMS = () => {
    setFeedback({
      loading: true,
      message: "Verifying OTP...",
      variant: "info",
    });
    axios
      .post(`${firebaseAddress}verifySMSOTP`, {
        userId: props.user.id,
        otp: formValue.code,
      },{
        headers: {
          Authorization: `Bearer ${props.access_token}`,
        }
      })
      .then((res) => {
        if (res.status === 200) {
          setVerified(true);
          setFeedback(null);
        } else {
          setFeedback({
            message: "Something went wrong",
            variant: "danger",
          });
        }
      })
      .catch((error) => {
        if (error.response.data.status === 500) {
          setFeedback({
            message: "Wrong OTP",
            variant: "danger",
          });
        } else {
          setFeedback({
            message: "Something went wrong",
            variant: "danger",
          });
        }
      });
  };

  const recoverPassword = (answers, otp) => {
    var verify = generateVerification(answers);
    axios
      .post(`${firebaseAddress}getEncryptedPassword`, {
        userId: props.user.id,
        verification: verify,
        otp: otp,
      },{
        headers: {
          Authorization: `Bearer ${props.access_token}`,
        }
      })
      .then((res) => {
        var encryptedPassword = res.data.data.encryptedPassword;
        var key = generateKey(answers);
        var cipherParams = CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Base64.parse(encryptedPassword),
        });
        var wordArrayKey = CryptoJS.enc.Hex.parse(key);
        var iv = CryptoJS.enc.Base64.parse("9De0DgMTCDFGNokdEEial");

        // Decrypt password
        var password = CryptoJS.AES.decrypt(cipherParams, wordArrayKey, {
          iv: iv,
        }).toString(CryptoJS.enc.Utf8);

        // Remove padding
        var pipe = password.indexOf("|");
        password = password.substring(pipe + 1);
        pipe = password.indexOf("|");
        password = password.substring(0, pipe);
        setMessage(password);
        setSuccess(true);
        setHeading("Your Password Is:");
        setSampleModalOpened(true);
      })
      .catch((err) => {
        const errmsg = err.response.data.errormsg;
        setHeading(errmsg);
        if (errmsg === "Wrong OTP!") {
          setMessage("Please recheck your OTP");
          setSampleModalOpened(true);
        } else if (errmsg === "Verification not match, failed") {
          setMessage("Please recheck your answers");
          setSampleModalOpened(true);
        }
      });
  };

  const closeModal = () => {
    setSampleModalOpened(false);
    if (success) {
      setTimeout(() => {
        navigate("/new");
      }, 500);
    }
  };

  const verifyBody = (
    <Fragment>
      <Form
        className="align-items-center"
        style={{ width: "100%", display: "contents" }}
        onSubmit={(e) => {
          e.preventDefault();
          verifySMS();
        }}
      >
        <Form.Group className="mt-3">
          <Row className="mb-2">
            <Col
              xs={12}
              lg={2}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
              className={`mb-2 mb-lg-0 
                    justify-content-center justify-content-lg-end`}
            >
              SMS Confirmation
            </Col>
            <Col xs={12} lg={8}>
              <Form.Control
                type="text"
                value={formValue.code}
                name="code"
                onChange={onChange}
              />
            </Col>
            <Col xs={12} lg={2}>
              <Button
                onClick={() => {
                  sendTwilioSMS();
                }}
                disabled={SMSsent}
                className="w-100"
                variant="danger"
                style={{ backgroundColor: "#800000", border: "none" }}
              >
                {sentOnce ? "Resend" : "Send Code"}
              </Button>
            </Col>
          </Row>
        </Form.Group>
        <FeedbackAlert feedback={otpFeedback} className="mt-3 mb-0" />
        <LoadingButton
          type="submit"
          disabled={!SMSsent || (feedback && feedback.loading)}
          className="mt-4 w-100"
        >
          Submit
        </LoadingButton>
        <FeedbackAlert feedback={feedback} className="mt-3 mb-0" />
      </Form>
    </Fragment>
  );

  const changeBody = (
    <Fragment>
      <TextModal
        heading={heading}
        message={message}
        show={sampleModalOpened}
        handleClose={() => closeModal()}
      />
      <Form
        className="align-items-center"
        style={{ width: "100%", display: "contents" }}
        onSubmit={(e) => {
          e.preventDefault();
          recoverPassword(formValue.answers.join(""), formValue.code);
        }}
      >
        <SecurityQuestionCheck
          answers={formValue.answers}
          onChangeAnswer={onChangeAnswer}
          user={props.user}
        />
        <Button
          type="submit"
          className="mt-4 w-100"
          variant="danger"
          style={{ backgroundColor: "#800000", border: "none" }}
        >
          Submit
        </Button>
      </Form>
    </Fragment>
  );

  return (
    <ManageAccountOptsPage
      title={verified ? "Please provide answers" : "Recover Password"}
      background={background}
      body={verified ? changeBody : verifyBody}
    />
  );
}
