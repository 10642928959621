import axios from "axios";
import { useState, useEffect } from "react";
import {
  createUserKey,
  encodeReq,
  formCheck,
  uint8ArrayToWordArray,
  XOR,
  arraysEqual,
  convertWordArrayToUint8Array,
} from "../functions/encoding";
import CryptoJS from "crypto-js";

export const serverUrl = process.env.REACT_APP_SERVER_URL;

const keyLabel = "cynorix_key";
const expireLabel = "cynorix_expire";
const registeredLabel = "registered";


export function useSecureCommunication(user, gapi) {
  const [isSessionExpired, setIsSessionExpired] = useState(true);
  const [isKeyGenerating, setIsKeyGenerating] = useState(false);

  function refreshKey() {
    setIsSessionExpired(false);
    genKey();
  }

  /* 
     await postWithCredentials(SERVER_URL + "updateServiceCount", {
            email: currentUser.email,
            id: currentUser.uid,
            service: service,
            count: "1",
        })  
  */

  // Generate the key for the session
  async function genKey() {
    setIsKeyGenerating(true);
    // for now, send request to backend with some basic info
    await axios.post(serverUrl + "generateKey", {
      userExists: true,
      userEmail: user.email,
      pbValidVecBase64: null,
      }).then((res) => {
        setIsKeyGenerating(false);
      })
      .catch((err) => {
        setIsKeyGenerating(false);
      });
  }


  useEffect(() => {
    beginSession();

    return () => {
      endSession();
    };
  }, []);

  //calculates time from key expiry, and sets timeout to block page when key expires
  function beginSession() {
    var expire = localStorage.getItem(expireLabel);

    var currDate = new Date();
    var expireDate = new Date(parseInt(expire));
    var genDate = new Date(parseInt(expire));

    genDate.setMinutes(expireDate.getMinutes() - 30);
    expireDate.setMinutes(expireDate.getMinutes() - 2); //add 2 minute buffer

    var diffInMs = expireDate.getTime() - currDate.getTime();
    sessionTimeout = setTimeout(endSession, diffInMs);

  }

  //triggers modal to indicate session has ended
  function endSession() {
    setIsSessionExpired(true);
    localStorage.removeItem(expireLabel);
    localStorage.removeItem(keyLabel);
    localStorage.removeItem(registeredLabel);
  }

  return {
    refreshKey,
    isKeyGenerating,
  };
}

var sessionTimeout;

window.addEventListener("beforeunload", () => {
  if (localStorage.getItem(expireLabel) === "generating") {
    localStorage.removeItem(expireLabel);
    localStorage.removeItem(keyLabel);
    localStorage.removeItem(registeredLabel);
  }
});


// --- general use functions --- //

// updates database to match files in google drive (in case user has trashed/deleted files)
function updateFiles(gapi, user) {
  //request list of trashed files on google drive
  const trashedPromise = new Promise((resolve, reject) => {
    gapi.client.drive.files
      .list({
        copora: "user",
        q: "name contains '.enc' and trashed = true",
      })
      .then(
        function (response) {
          var rawFiles = response.result.files;

          var trashedIds = [];
          for (var i = 0; i < rawFiles.length; ++i) {
            trashedIds.push(rawFiles[i].id);
          }
          resolve(trashedIds);
        },
        function (err) {
          reject(err);
        }
      );
  });

  //request list of untrashed files on google drive
  const untrashedPromise = new Promise((resolve, reject) => {
    gapi.client.drive.files
      .list({
        corpora: "user",
        q: "name contains '.enc' and trashed = false",
      })
      .then(
        function (response) {
          var rawFiles = response.result.files;

          var untrashedIds = [];
          for (var i = 0; i < rawFiles.length; ++i) {
            untrashedIds.push(rawFiles[i].id);
          }
          resolve(untrashedIds);
        },
        function (err) {
          reject(err);
        }
      );
  });

  Promise.all([trashedPromise, untrashedPromise])
    .then((files) => {
      //request to update files on server
      var xhr = new XMLHttpRequest();
      var getFilesReq = {
        userId: user.id,
        trashed: files[0],
        untrashed: files[1],
      };
      xhr.open("POST", serverUrl + "updateFiles", true);
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.responseType = "json";
      xhr.onload = function () {

        if (xhr.status == 500) {
          //
        } else {
        }
      };
      xhr.onerror = function (err) {
      };
      xhr.send(encodeReq(getFilesReq, user.email));
    })
    .catch((err) => {
    });
}

export async function verifyPassword(
  userId,
  password,
  access_token,
  redirectIfLocked = () => {}
) {
  const check = formCheck(password + userId);

  return new Promise((resolve, reject) => {
      axios
      .post(`${serverUrl}verifyPassword`, {
        userId: userId,
        toCheck: check,
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }})
      .then((res) => {

        if (typeof res.data.data.valid === "boolean") {
          // temp fix
          if (res.data.data.locked === true) {
            reject("Account is locked.");
            redirectIfLocked();
          }
          resolve(res.data.data.valid);
        } else {
          reject("Valid is not a boolean type: " + res.data.data.valid);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function verifyLockedPassword(
  userId,
  password,
  access_token
) {
  const check = formCheck(password + userId);

  return new Promise((resolve, reject) => {
    axios
      .post(`${serverUrl}verifyPassword`, {
        userId: userId,
        toCheck: check,
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }})
      .then((res) => {
        if (typeof res.data.data.valid === "boolean") {

          resolve(res.data.data.valid);
        } else {
          reject("Valid is not a boolean type: " + res.data.data.valid);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// uses gmail api to send email from current user's account
export function sendEmail(subject, emailMsg, recipient, user) {
  //compose email and encode to base64 string
  const messageParts = [
    "From: " + user.email,
    "To: " + recipient,
    "Content-Type: text/html; charset=utf-8",
    "MIME-Version: 1.0",
    `Subject: ${subject}`,
    "",
    emailMsg,
  ];
  const message = messageParts.join("\n");
  var encodedMessage = btoa(message);

  return new Promise((resolve, reject) => {
    window.gapi.client.gmail.users.messages
      .send({
        userId: "me",
        resource: {
          raw: encodedMessage,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject("sending email failed: " + err);
      });
  });
}

function bytesToWordArray(bytes) {
  var words = [];
  for (var i = 0; i < bytes.length; ++i) {
    var j = 24 - (i % 4) * 8;
    words[i >>> 2] |= bytes[i] << j;
  }
  return CryptoJS.lib.WordArray.create(words, bytes.length);
}

function wordArrayToBytes(wa) {
  let bytes = [];
  for (var i = 0; i < wa.sigBytes; ++i) {
    var j = 24 - (i % 4) * 8;
    bytes.push((wa.words[i >>> 2] >>> j) & 0xff);
  }
  return new Uint8Array(bytes);
}

export async function downloadFile(
  pwd,
  fileId,
  user,
  access_token,
  pushFeedback = () => {},
  pushDownloadProgress = () => {},
  redirectIfLocked = () => {}
) {
  // validate password
  pushFeedback({
    variant: "info",
    loading: true,
    message: `Validating Password...`,
  });
  try {
    const isPwdValid = await verifyPassword(user.id, pwd, access_token, redirectIfLocked);

    if (!isPwdValid) {
      pushFeedback({
        variant: "warning",
        message: `Password incorrect for user ${user.name}(${user.email}).`,
      });
      return;
    }
  } catch (err) {
    pushFeedback({
      variant: "danger",
      message: `An unexpected error occurred while verifying password.`,
    });
  }

  // get file contents
  try {
    pushFeedback({
      variant: "info",
      loading: true,
      message: `Retrieving File Contents...`,
    });
    const res = await window.gapi.client.drive.files.get({
      fileId: fileId,
      fields: 'size',
    });

    console.log(res.result.size);

    await getTransformed(pwd, fileId, res.result.size, user, access_token, pushFeedback, pushDownloadProgress);
  } catch (err) {
    const reason = err.result.error.errors[0].reason;
    if (reason === "notFound") {
      pushFeedback({
        variant: "danger",
        message: `File not found.`,
      });
    } else {
      pushFeedback({
        variant: "danger",
        message: `An unexpected error occurred while getting the file.`,
      });
    }
  }
}
function str2ab(str) {
  var buf = new ArrayBuffer(str.length); // 2 bytes for each char
  var bufView = new Uint8Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}
async function getTransformed(
  pwd,
  fileId,
  fileSize,
  user,
  access_token,
  pushFeedback = () => {},
  pushDownloadProgress = () => {}
) {
  pushFeedback({
    variant: "info",
    loading: true,
    message: `Decrypting file...`,
  });
  // reform user encryption key
  var userKey = createUserKey(fileId, pwd, user.id);
  var pwdCheck = formCheck(pwd + user.id);
  try {
    const res = await axios.post(`${serverUrl}getTransformedKey`, {
      fileId: fileId,
      toCheck: pwdCheck,
      userId: user.id,
    });

    // MAKE SURE THAT THE FILE EXISTS AND CLAIMED IS TRUE
    const { exists, claimed } = res.data.data;
    if (exists === false) {
      pushFeedback({
        variant: "danger",
        message: `The file you are looking for cannot be found.`,
      });
      return;
    } else if (claimed === false) {
      pushFeedback({
        variant: "danger",
        message: `File Unclaimed.`,
      });
      return;
    }
    pushDownloadProgress({
      loading: true,
      now: 0
    });

    // GET KEY FOR DECRYPTING FILE
    const encFileKey = res.data.data.key;
    const fileKeyAsBytes = XOR(encFileKey, userKey);
    const fileKeyAsWords = bytesToWordArray(fileKeyAsBytes);

    const SLICE_SIZE = 50 * 1024 * 1024; //50 MB

    var accessToken = window.gapi.auth2
    .getAuthInstance()
    .currentUser.get()
    .getAuthResponse().access_token;

    let firstSlice = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
        range: `bytes=${0}-${SLICE_SIZE-1}`
      },
      responseType: 'blob'
    });

    const iv = bytesToWordArray(
      new Uint8Array(await firstSlice.data.slice(0,16).arrayBuffer())
    );

    let aesDec = CryptoJS.algo.AES.createDecryptor(fileKeyAsWords, {
      mode: CryptoJS.mode.CFB,
      iv: iv
    });

    let decryptedBlobParts =[];

    let firstInput = CryptoJS.lib.WordArray.create( await firstSlice.data.slice(16).arrayBuffer() );
    let firstDecryptedSlice = aesDec.process(firstInput);
    decryptedBlobParts.push(new Blob([wordArrayToBytes(firstDecryptedSlice)]));
    firstSlice = null; //let javascript garbage collect

    pushDownloadProgress({
      loading: true,
      now: Math.round(SLICE_SIZE*100/fileSize)
    });

    let start = SLICE_SIZE;

    const delay = () => new Promise((resolve) => setTimeout(resolve,0)); //bypass react optimization
    while (start < fileSize) {
      let slice = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
      headers: {
        authorization: `Bearer ${accessToken}`,
        range: `bytes=${start}-${start+SLICE_SIZE-1}`
      },
      responseType: 'blob' 
    });

      let wordArrayInput = CryptoJS.lib.WordArray.create( await slice.data.arrayBuffer() );
      let decryptedSlice = aesDec.process(wordArrayInput);

      decryptedBlobParts.push(new Blob([wordArrayToBytes(decryptedSlice)]));

      start += SLICE_SIZE;

      pushDownloadProgress({
        loading: true,
        now: Math.round(start*100/fileSize)
      });
      await delay();
    }

    decryptedBlobParts.push(new Blob([wordArrayToBytes(aesDec.finalize())]));
    const decryptedFileBlob = new Blob(decryptedBlobParts, { type: "application/octet-stream" });

    // separate file signature from file
    const decLength = decryptedFileBlob.size;
    console.log(decLength);
    const sigLength = 32;
    const sigAsBlob = decryptedFileBlob.slice(decLength - sigLength);
    const sigAsBytes = new Uint8Array(await sigAsBlob.arrayBuffer());
    const fileAsBlob = decryptedFileBlob.slice(0, decLength - sigLength);

    // get hashed signature of decrypted file to compare with expected signature
    const sigCheckWords = CryptoJS.SHA256(fileAsBlob); // create file signature
    const sigCheckAsBytes = wordArrayToBytes(sigCheckWords);

    // download file if signature is correct
    if (!arraysEqual(sigAsBytes, sigCheckAsBytes)) {

      pushFeedback({
        variant: "danger",
        message: `The file is corrupted or was incorrectly decrypted. Download aborted.`,
      });
      return;
    }
    pushDownloadProgress({
      loading: false,
      now: 0
    });
    try {
      const res = await axios.get(
        `https://www.googleapis.com/drive/v3/files/${fileId}`,
        {
          params: {
            fields: "name",
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      var a = document.createElement("a");
      var url = window.URL.createObjectURL(fileAsBlob);
      var filename = res.data.name;
      a.href = url;
      a.download = filename.replace(".enc", "");
      a.click();
      window.URL.revokeObjectURL(url);
      pushFeedback(null);
    } catch (err) {
      pushFeedback({
        variant: "danger",
        message: `Error occured while getting title of file. Download aborted.`,
      });
    }
  } catch (err) {
    pushFeedback({
      variant: "danger",
      message: `An unexpected error occured while trying to retrieve the 
        file's transformed key. Please try again.`,
    });
    console.log(err);
  }
}

export function getFileName(fileId) {
  var accessToken = window.gapi.auth2
    .getAuthInstance()
    .currentUser.get()
    .getAuthResponse().access_token;
  return new Promise((resolve, reject) => {
    axios
      .get(`https://www.googleapis.com/drive/v3/files/${fileId}`, {
        params: {
          fields: "name",
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        resolve(res.data.name);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
