import React, { useEffect, useState } from "react";
import { Container, Card, Col, Form, Row, Stack } from "react-bootstrap";
import "../CustomBootstrap.scss";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function SecurityQuestionCheck(props) {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (props.user && !loading) {
      axios
        .post(SERVER_URL + "getQuestions", {
          userId: props.user.id,
        })
        .then((res) => {
          if (res.data.success) {
            setQuestions(res.data.data);
            if (props.setQuestions) {
              props.setQuestions(res.data.data);
              setLoading(true);
            }
          }
        });
    }
  }, [props.user]);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Container style={{ paddingLeft: "0px" }}>
        {questions.map((question, index) => (
          <Form.Group className="mb-3">
            <Row className="mb-2">
              <Form.Label column className="text-center text-lg-end px-0">
                Security Question
              </Form.Label>
              <Col xs={12} lg={10}>
                <Form.Control value={question} disabled />
              </Col>
            </Row>
            <Row className="my-2">
              <Form.Label column className="text-center text-lg-end px-0">
                Answer
              </Form.Label>
              <Col xs={12} lg={10}>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  value={props.answers[index]}
                  name="newPassword"
                  onChange={(event) => {
                    props.onChangeAnswer(index, event.target.value);
                  }}
                  autoFocus={index === 0}
                />
              </Col>
            </Row>
          </Form.Group>
        ))}
      </Container>
    </>
  );
}
