import React, { Component } from "react";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import "./Faq.css";
import lock from "../assets/icons/svg/lock.svg";
import tool from "../assets/icons/svg/tool.svg";
import { Card, Row, Col } from "react-bootstrap";
import BannerImage from "./BannerImage";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      q1: { arrow: "right-arrow", visibility: "hidden" },
      q2: { arrow: "right-arrow", visibility: "hidden" },
      q3: { arrow: "right-arrow", visibility: "hidden" },
      q4: { arrow: "right-arrow", visibility: "hidden" },
      q5: { arrow: "right-arrow", visibility: "hidden" },
      q6: { arrow: "right-arrow", visibility: "hidden" },
      q7: { arrow: "right-arrow", visibility: "hidden" },
      q8: { arrow: "right-arrow", visibility: "hidden" },
    };
    this.foldContent = this.foldContent.bind(this);
  }

  foldContent(question) {
    let arrow;
    let visibility;
    if (this.state[question].arrow == "") {
      arrow = "right-arrow";
      visibility = "hidden";
    } else {
      arrow = "";
      visibility = "visible";
    }
    this.setState({
      [question]: { arrow: arrow, visibility: visibility },
    });
  }

  render() {
    return (
      <Card
        style={{
          border: "none",
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Card.Body>
        <BannerImage />
          <h4 className="mt-3">
            <b>Frequently Asked Questions</b>
          </h4>
          <hr className="mt-0" />
          <Row>
            <Col md={6} sm={12} style={{ marginBottom: 10 }}>
              <MDBAccordion alwaysOpen>
                <div class="card rounded-0">
                  <div className="card-body faq-card-body rounded-0">
                    <img class="icon" src={lock} />
                    <b>Security</b>
                  </div>
                </div>
                <MDBAccordionItem
                  collapseId={1}
                  headerTitle="Does Cynorix have access to my file data?"
                >
                  <strong>No. </strong>
                  Encrypted files do not leave a sender's computer unless through
                  the sharing mechanism selected by the sender. Cynorix does not
                  have access to users' encrypted or unencrypted files. Also,
                  Cynorix neither has access to, nor the ability to reconstruct
                  any of the encryption keys.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={2}
                  headerTitle="Are payments secure?"
                >
                  <strong>
                    We work with top payment companies which guarantees your
                    safety and security.{" "}
                  </strong>
                  All billing information is stored by our payment processing
                  partner.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={3}
                  headerTitle="Does my password get stored anywhere?"
                >
                  <strong>No. </strong>
                  Your password is hashed and inaccessible to Cynorix or third parties.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={3}
                  headerTitle="Do you offer 2-Factor Authentication?"
                >
                  <strong>Yes. </strong>
                  We offer strong 2-Factor SMS Authentication for you to ensure your account is secure.
                </MDBAccordionItem>
              </MDBAccordion>
            </Col>
            <Col>
              <MDBAccordion alwaysOpen>
                <div class="card rounded-0">
                  <div className="card-body faq-card-body rounded-0">
                    <img class="icon" src={tool} />
                    <b>Tool Usage</b>
                  </div>
                </div>
                <MDBAccordionItem
                  collapseId={1}
                  headerTitle="What are the requirements to use this tool?"
                >
                  To start using the tool, you need a device with a browser
                  and a mobile device (if SMS is selected for two‐factor
                  authentication).
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={2}
                  headerTitle="Is there a free trial?"
                >
                  <strong>Of course!</strong> We’re happy to offer a free plan
                  to anyone who wants to try our service.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={3}
                  headerTitle="What if I reach the limit? Can I still use the service?"
                >
                  <strong>
                    You may share more files after reaching your service limit
                    by buying chunks in My Subscription - Buy
                    Chunks.{" "}
                  </strong>
                  You will be able to purchase the number of extra shares you
                  need based on the price corresponding with your subscription.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={3}
                  headerTitle="When does my subscription update? Why can't I delete my account?"
                >
                  <strong>
                    Changes to your subscription and account will occur on a monthly
                    basis.
                  </strong>{" "}
                  If you change or cancel your subscription or account, they will go
                  through next month.
                </MDBAccordionItem>
              </MDBAccordion>
            </Col>
            <p class="text-center text-black my-4 note">
              If you have any other questions, <a href="/contact">Contact Us</a>
            </p>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

export default Faq;
