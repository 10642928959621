import axios from "axios";
import { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Image,
  ListGroup,
  Spinner,
  Stack,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { serverUrl } from "../util/SecureCommunication";
import BuyChunks from "./BuyChunks";
import "./styles/SideBar.css";

const accountDropdownOptions = [
  {
    name: "Change Security Questions",
    link: "/change-security-questions",
    cond: ({ isMaintenance }) => !isMaintenance,
  },
  {
    name: "Change Password",
    link: "/change-password",
  },
  {
    name: "Change Phone Number",
    link: "/change-phone-number",
  },
  {
    name: "Recover Password",
    link: "/recover-password",
  },
  {
    name: "Verify Coupon",
    link: "/verify-coupon",
  },
  {
    name: "Remove Account",
    link: "/remove-account",
    cond: ({ isMaintenance }) => !isMaintenance,
  },
  {
    name: "Maintenance Dashboard",
    link: "/maintenance-dashboard",
    cond: ({ isMaintenance }) => isMaintenance,
  },
];

const INELIGIBLE_FOR_TRIAL = 0;
const ELIGIBLE_FOR_TRIAL = 1;
const ACTIVE_TRIAL = 2;
const PAID_USER = 3;
const PLAN_STATE_SPONSORED = 4;
const BUY_SHARE_CHUNK_ELIGIBLE = 5;
const BUY_FILE_CHUNK_ELIGIBLE = 6;
const BUY_BOTH_CHUNK_ELIGIBLE = 7;
const subscriptionDropdownOptions = [
  {
    name: "My Service Usage",
    link: "/service-usage-info",
    cond: ({ payLevel }) => payLevel >= ACTIVE_TRIAL,
  },
  {
    name: "Change Subscription",
    link: "/upgrade-service",
    cond: ({ payLevel }) => payLevel >= PAID_USER 
  },
  {
    name: "Purchase Subscription",
    link: "/upgrade-service",
    cond: ({ payLevel }) => payLevel <= ACTIVE_TRIAL,
  },
  {
    name: "Free Trial",
    link: "/free-trial",
    cond: ({ payLevel }) => payLevel === ELIGIBLE_FOR_TRIAL,
  },
  {
    name: "Sponsorship Requests",
    link: "/accept-sponsorship-req",
    cond: ({ payLevel }) => payLevel === PAID_USER || payLevel >= BUY_SHARE_CHUNK_ELIGIBLE
  },
  {
    name: "Buy More Shares",
    link: "/select-chunks",
    cond: ({ payLevel }) => payLevel === BUY_SHARE_CHUNK_ELIGIBLE || payLevel === BUY_BOTH_CHUNK_ELIGIBLE,
  },
  {
    name: "Buy More Encryptions",
    link: "/select-file-chunks",
    cond: ({ payLevel }) => payLevel === BUY_FILE_CHUNK_ELIGIBLE || payLevel === BUY_BOTH_CHUNK_ELIGIBLE,
  },
  {
    name: "Cancel Service",
    link: "/cancel-service",
    cond: ({ payLevel }) => payLevel === PAID_USER || payLevel >= BUY_SHARE_CHUNK_ELIGIBLE
  },
  {
    name: "Cancel Sponsorship",
    link: "/cancel-sponsorship",
    cond: ({ payLevel }) => payLevel === PLAN_STATE_SPONSORED,
  },
  // need sponsorship var first
  // {
  //   name: "Cancel Sponsorship",
  //   link: "/cancel-sponsorship",
  //   cond: ({ payLevel }) => payLevel === PLAN_STATE_PAID_SPONSOR,
  // },
];

const fileSharingListOptions = [
  {
    name: "Encrypt",
    link: "/new",
  },
  {
    name: "Files",
    link: "/sheets",
  },

  {
    name: "Help",
    link: "/faq",
  },
];

export default function SideBar(props) {
  // get current location
  const location = useLocation();
  const navigate = useNavigate();
  const maintenanceEmail = process.env.REACT_APP_MAINTENANCE_EMAIL;
  // user
  const [user, setUser] = useState(props.user);
  const [isAuthorizing, setIsAuthorizing] = useState(props.isAuthorizing);
  useEffect(() => {
    setIsAuthorizing(props.isAuthorizing);
    setUser(props.user);
    // console.log(props.user)
    if (props.user !== null) {
      checkFreetrialEligible();
    }
  }, [props.user, props.isAuthorizing, location]);

  const [payState, setPayState] = useState(ELIGIBLE_FOR_TRIAL);

  // perform a check if account is locked
  // also more options if the user is paid
  function checkFreetrialEligible() {
    axios
      .post(`${serverUrl}checkFreetrialEligible`, {
        userId: props.user.id,
        userEmail: props.user.email,
      })
      .then((res) => {
        const { freetrial, paid, fileSharesLeft, fileEncryptsLeft, sponsored } = res.data.data;
        if (freetrial === "active" && paid === false) {
          setPayState(ACTIVE_TRIAL);
        } else if (freetrial === "eligible" && paid === false) {
          setPayState(ELIGIBLE_FOR_TRIAL);
        } else if (freetrial === "ineligible") {
          if (paid === true) {
            if (fileSharesLeft <= 0 && fileEncryptsLeft <= 0) {
              setPayState(BUY_BOTH_CHUNK_ELIGIBLE);
            } else if (fileSharesLeft <= 0 && !(fileEncryptsLeft <= 0)) {
              setPayState(BUY_SHARE_CHUNK_ELIGIBLE);
            } else if (fileEncryptsLeft <= 0 && !(fileSharesLeft <= 0)) {
              setPayState(BUY_FILE_CHUNK_ELIGIBLE);
            } else {
              setPayState(PAID_USER);
            }
          } else if (sponsored) {
            setPayState(PLAN_STATE_SPONSORED)
          } else if (paid === false) {
            setPayState(INELIGIBLE_FOR_TRIAL);
          } else {
            throw Error('"paid" not a boolean value');
          }
        } else {
          throw Error(
            `Unexpected logic here: freetrial is ${freetrial} and paid is ${paid}`
          );
        }
      })
      .catch((err) => {});
  }

  if (user === null || user === undefined) {
    return (
      <Card>
        <Card.Body>
          <Button
            variant="light"
            style={{ width: "100%", textAlign: "left", padding: 17 }}
            // disabled
            onClick={() => {
              props.handleAuthClick();
            }}
          >
            {isAuthorizing ? (
              <Stack direction="horizontal" gap={2}>
                <Spinner size="sm" /> <h5 className="m-0">Signing In...</h5>
              </Stack>
            ) : (
              <h5 className="m-0">Not Signed In</h5>
            )}
          </Button>
        </Card.Body>
      </Card>
    );
  }

  // user is not null or undefined
  const isMaintenance = maintenanceEmail === user.email;

  function navListGroup(navListItems) {
    const listItems = [];
    if (location.pathname === "/set-password") return null;

    for (const navListItem of navListItems) {
      const { name, link, cond } = navListItem;
      if (!cond || cond({ payLevel: payState, isMaintenance })) {
        const isHere = link === location.pathname;
        listItems.push(
          <ListGroup.Item
            key={link}
            action
            active={isHere}
            style={{
              border: "none",
              borderRadius: 5,
              fontWeight: isHere ? "bold" : "normal",
              textAlign: "left",
            }}
            onClick={() => {
              navigate(link);
            }}
          >
            {name}
          </ListGroup.Item>
        );
      }
    }

    return (
      <ListGroup
        className="p-1 py-2"
        style={{ padding: 5, border: "1px solid #d9d9d9" }}
      >
        {listItems}
      </ListGroup>
    );
  }

  return (
    <Card body>
      {/* Profile Dropdown */}
      <Accordion>
        <Accordion.Item eventKey="user">
          <Accordion.Header>
            <Image
              referrerPolicy="no-referrer"
              roundedCircle
              src={user.imageUrl}
              style={{
                height: 30,
                marginRight: 10,
              }}
            />
            <ul className="list-unstyled m-0">
              <li>
                <h5 className="m-0">{user.name}</h5>
              </li>
              <li>
                <span className="m-0 small text-muted">{user.email}</span>
              </li>
              {payState === ACTIVE_TRIAL ? (
                <li className=" small">
                  <hr className="my-1"></hr>
                  Free Trial
                </li>
              ) : null}
            </ul>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <div className="p-2">
              <Accordion
                alwaysOpen
                style={{
                  borderRadius: 5,
                  boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Accordion.Item eventKey="my-account">
                  <Accordion.Header>My Account</Accordion.Header>
                  <Accordion.Body className="p-2">
                    {navListGroup(accountDropdownOptions)}
                  </Accordion.Body>
                </Accordion.Item>
                {isMaintenance ? null : (
                  <Accordion.Item eventKey="my-sub">
                    <Accordion.Header>My Subscription</Accordion.Header>
                    <Accordion.Body className="p-2">
                      {navListGroup(subscriptionDropdownOptions)}
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </div>
            <hr className="my-0" />
            <div className="p-2">
              <Button
                variant="secondary"
                onClick={() => {
                  props.signOut();
                  navigate("/new");
                }}
                style={{ width: "100%" }}
              >
                Sign Out
              </Button>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />
      {/* Nav links */}
      {isMaintenance ? null : (
        <>
          {navListGroup(fileSharingListOptions)}
          <hr />
        </>
      )}
      <Button
        variant="secondary"
        style={{ width: "100%" }}
        onClick={() => {
          navigate(-1);
        }}
      >
        Go Back
      </Button>
    </Card>
  );
}
