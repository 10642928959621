import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import {
  convertWordArrayToUint8Array,
  createUserKey,
  uint8ArrayToString,
  XOR,
} from "../functions/encoding";
import CryptoJS from "crypto-js";
import "./Open.css";
import ComponentCard from "./ComponentCard.js";
import {
  serverUrl,
  verifyPassword,
  sendEmail,
  getFileName,
} from "../util/SecureCommunication";
import axios from "axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import FeedbackAlert from "./FeedbackAlert";
import BannerImage from "./BannerImage";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import InfoButton from "./InfoButton.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { getUserFromEmail } from "../functions/getUser.js";
library.add(faEye, faEyeSlash, faCircleInfo);

function assertDefined(items) {
  let notDefined = [];
  Object.entries(items).forEach(([name, value]) => {
    if (value === undefined || value === null) {
      notDefined.push(name);
    }
  });
  if (notDefined.length !== 0) {
    throw Error(`${notDefined.join(",")} are not defined.`);
  }
}

// share file on google drive and send notification email to recipient
// copied from original open.js
async function googleDriveShare(
  recipientEmail,
  key,
  keyRef,
  fileId,
  fileName,
  user,
  res_recipId,
  pushFeedback = () => { }
) {
  try {
    assertDefined({ fileId });
    if (keyRef === undefined) {
      throw Error("keyRef is not defined.");
    }
  } catch (err) {
    pushFeedback({
      variant: "danger",
      message: `The URL you have accessed contains some missing information.`,
    });
    return;
  }
  console.assert(keyRef !== undefined, "keyRef undefined");
  console.assert(fileName !== undefined, "filename undefined");
  console.assert(fileName !== null, "fileName null");
  const pageUrl = process.env.REACT_APP_FRONTEND_URL;

  var accessToken = window.gapi.auth2
    .getAuthInstance()
    .currentUser.get()
    .getAuthResponse().access_token;
  await axios
    .post(
      `https://www.googleapis.com/drive/v3/files/${fileId}/permissions`,
      {
        role: "writer",
        type: "user",
        emailAddress: recipientEmail,
      },

      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        params: {
          sendNotificationEmail: false,
        },
      }
    )
    .then((res) => {
      // construct email subject and body
      const keyStr = uint8ArrayToString(key);
      const subject = `Cynorix Secure File Sharing: New file "${fileName}"`;

      let searchParams = new URLSearchParams();
      let emailBody = "";
      searchParams.append("fileId", fileId);
      searchParams.append("key", keyStr);
      searchParams.append("owner", user.email);
      searchParams.append("recip", recipientEmail);
      searchParams.append("keyRef", keyRef);
      let receiveUrl = `${pageUrl}receive/?${searchParams.toString()}`;

      axios
        .post(`${serverUrl}updateClaimUrl`, {
          recip_id: res_recipId,
          file_id: fileId,
          recip_url: receiveUrl,
        })
        .then((res) => {
          axios
            .post(`${serverUrl}increaseShareCounter`, {
              userId: user.id,
            })
            .then((res) => { 
              // console.log(res)
            })
            .catch((err) => { console.log(err)});
        })
        .catch((err) => { });

      if (keyRef === null) {
        emailBody = `
        ${user.email} has shared the following file: <b> ${fileName}</b>
          <br>
          Please click <a id="sign-in" href="${receiveUrl}">here</a> 
          to claim the file with Cynorix Secure File Sharing
          <br>
        `;
      } else {
        emailBody = `
        ${user.name} would like to share a file with you. 
        Please click <a id="create-account" href="${pageUrl}set-password">here</a>
        to install Cynorix Secure File Sharing to your Google Drive.
        Once installed, click <a id="sign-in" href="${receiveUrl}">here</a> 
        to claim the file.
        <br>
        `;
      }
      pushFeedback({
        variant: "info",
        loading: true,
        message: `Sending email notification to recipient...`,
      });
      // send email to recipient to notify them that a file was shared
      sendEmail(subject, emailBody, recipientEmail, user)
        .then((res) => {
          pushFeedback({
            variant: "success",
            message: `File has been shared!`,
          });
        })
        .catch((err) => {
          pushFeedback({
            variant: "danger",
            message: `An error occurred while sending the email notification.`,
          });
        });
    })
    .catch(async (err) => {
      const errReason = err.response.data.error.errors[0].reason;
      if (errReason === "invalidSharingRequest") {
        await axios
          .post(`${serverUrl}removeShareWithInvalidEmail`, {
            recipId: res_recipId,
            recipEmail: recipientEmail,
            senderId: user.id,
            fileId: fileId,
          })
          .then(async (res) => {
            pushFeedback({
              variant: "danger",
              message: (
                <>
                  <b>Error while sharing file in Google Drive:</b>
                  <br />
                  You are trying to share a file with a user who does not have
                  an associated Google account to this email.
                </>
              ),
            });
          });
      } else if (errReason === "appNotAuthorizedToFile") {
        pushFeedback({
          variant: "danger",
          message: (
            <>
              <b>Error while sharing file in Google Drive:</b>
              <br />
              You are likely trying to share a file that you are not the owner
              of.
            </>
          ),
        });
      } else {
        pushFeedback({
          variant: "danger",
          message: `An error occurred while sharing the file in google drive.`,
        });
      }
    });
}

async function recipientInSystem(recipientEmail) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${serverUrl}checkForUser`, {
        recipientEmail,
      })
      .then((res) => {
        resolve(res.data.data.exists);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function shareFile(
  recipientEmail,
  pwd,
  fileId,
  pushFeedback = () => { },
  user,
  redirectIfLocked = () => { },
  accessToken
) {
  // preliminary checks:
  let recipExists;
  try {
    pushFeedback({
      variant: "info",
      loading: true,
      message: `Verifying Password for ${user.name}`,
    });
    const isPwdValid = await verifyPassword(user.id, pwd, redirectIfLocked);

    if (!isPwdValid) {
      pushFeedback({
        variant: "warning",
        message: `Password incorrect for user ${user.name}(${user.email}).`,
      });
      return;
    }

    recipExists = await recipientInSystem(recipientEmail);
  } catch (err) {
    pushFeedback({
      variant: "danger",
      message: `An error occurred while verifying your password and
         verifying if the recipient is a cynorix user.`,
    });
  }
  pushFeedback({
    variant: "info",
    loading: true,
    message: `Sharing file...`,
  });

  // encrypt pwd
  const userKey = createUserKey(fileId, pwd, user.id);
  const randomKey1 = convertWordArrayToUint8Array(
    CryptoJS.lib.WordArray.random(32)
  );
  const randomKey2 = convertWordArrayToUint8Array(
    CryptoJS.lib.WordArray.random(32)
  );
  const rk1XORrk2 = XOR(randomKey1, randomKey2);
  const ukXORrk1 = XOR(userKey, randomKey1);

  await axios
    .post(`${serverUrl}addShareKeys`, {
      recipientEmail: recipientEmail,
      fileId: fileId,
      encRandKey: rk1XORrk2,
      encHashedKey: ukXORrk1,
      recipExists: recipExists,
      userId: user.id,
    })
    .then(async (res) => {
      const keyRef = res.data.data.shareKeyRef;
      const fileName = res.data.data.fileName;
      const res_recipId = res.data.data.res_recipId;
      if (res.data.data.reshare === true) {
        pushFeedback({
          variant: "warning",
          message: `This file has already been shared with this person.`,
        });
        return false;
      }
      await googleDriveShare(
        recipientEmail,
        randomKey2,
        keyRef,
        fileId,
        fileName,
        user,
        res_recipId,
        pushFeedback
      );
    })
    .catch((err) => {
      console.log(err)
      const msg = err.response.data.errormsg;
      const ERR_SHARE_CAPPED = "account capped";
      if (msg === ERR_SHARE_CAPPED) {
        axios
          .post(`${serverUrl}checkFreetrialEligible`, {
            userId: user.id,
            userEmail: user.email,
          })
          .then(async (res) => {
            const { freetrial, paid } = res.data.data;
            const sponsorId = (await getUserFromEmail(user.email, accessToken, user.id)).userData.sponsor_id;
            if (sponsorId) {
              pushFeedback({
                variant: "danger",
                message: (
                  <>
                    Your sponsorship has reached its limit on file sharing.{" "}
                    Please ask your sponsor to purchase more shares.
                  </>
                ),
              });
            } else {
              pushFeedback({
                variant: "danger",
                message: (
                  <>
                    You have reached your limit on file sharing.{" "}
                    {(freetrial !== "active" || paid !== false) && (
                      <>
                        <Link to="/select-chunks">Buy More Chunks</Link> or{" "}
                      </>
                    )}
                    <Link to="/show-service-class">Upgrade Your Plan</Link>
                  </>
                ),
              });
            }
          })
          .catch((err) => { });
      } else {
        pushFeedback({
          variant: "danger",
          message: `An error occurred while sharing.`,
        });
      }
    });
}

export default function Open(props) {
  const [isShareFormValidated, setIsShareFormValidated] = useState(false);
  const [shareFormFeedback, setShareFormFeedback] = useState(null);
  const [fileName, setFileName] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const [user, setUser] = useState(props.user);
  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [fileID, setFileID] = useState("");
  const navigate = useNavigate();
  // update name of file to open based on fileId in url
  useEffect(() => {
    var fileId;
    if (searchParams.has("fileId")) {
      fileId = searchParams.get("fileId");
    } else if (searchParams.has("state")) {
      fileId = JSON.parse(searchParams.get("state")).ids[0];
    } else {
      fileId = null;
    }
    setFileID(fileId);
    if (fileId === null) {
      setShareFormFeedback({
        loading: true,
        message: "No file ID found. Redirecting to files...",
        variant: "warning",
      });
      setTimeout(() => {
        navigate("/sheets");
      }, 3000);
    } else {
      getFileName(fileId)
        .then((name) => {
          setFileName(name);
        })
        .catch((err) => {
          setFileName("");
        });
    }
  }, [searchParams]);

  const helpContent = (
    <p className="mb-1">
      Users can share files with anyone with a valid email. An email will be automatically
      sent, including information to claim the file and sign up for an account if the recipient does not have one.
    </p>
  );


  return (
    <ComponentCard title="Share File" helpContent={helpContent}>

      <hr className="mt-0" />
      <Form
        noValidate
        onSubmit={async (e) => {
          e.preventDefault();
          // there exists an invalid field
          if (e.target.checkValidity() === false) {
            // check if the emailField is valid
            let message = "Invalid Fields: ";
            if (e.target.recipientEmail.checkValidity() === false) {
              message += "Please enter a valid email. ";
            }
            if (e.target.accountPass.checkValidity() === false) {
              message += "Please provide a password.";
            }
            setShareFormFeedback({
              variant: "danger",
              message,
            });
            e.stopPropagation();
          } else {
            // call API here and handle response
            await shareFile(
              e.target.recipientEmail.value.toLowerCase(),
              e.target.accountPass.value,
              fileID,
              (feedback) => {
                if (feedback && feedback.message == "File has been shared!") {
                  e.target.recipientEmail.value = "";
                  e.target.accountPass.value = "";
                }
                setShareFormFeedback(feedback);
              },
              user,
              () => {
                navigate("/unlock");
              },
              props.access_token
            );
          }
          setIsShareFormValidated(true);
        }}
      >
        <Form.Group className="mb-3 row" controlId="share-recip-email">
          <Form.Label column>Recipient Email</Form.Label>
          <Col xs={12} lg={9} xl={10}>
            <Form.Control
              name="recipientEmail"
              autoComplete="off"
              type="email"
              placeholder="Email"
              required
            />
          </Col>
          <Form.Control.Feedback type="invalid">
            Please fill in a valid email.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-4 row" controlId="share-account-password">
          <Form.Label column>Your Password</Form.Label>
          <Col xs={12} lg={9} xl={10}>
            <div className="pass-wrapper">
              <Form.Control
                className="form-control"
                type={passwordShown ? "text" : "password"}
                name="accountPass"
                autoComplete="new-password"
                placeholder="Password"
                required
              />
              <i className="test">
                <FontAwesomeIcon
                  icon={passwordShown ? "eye-slash" : "eye"}
                  onClick={togglePassword}
                />
              </i>
            </div>
          </Col>
          <Form.Control.Feedback type="invalid">
            Please fill in your password.
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          type="submit"
          aria-label="Share File"
          className="w-100"
          variant="primary"
          disabled={
            shareFormFeedback !== null && shareFormFeedback.loading === true
          }
        >
          Share
        </Button>
      </Form>
      <FeedbackAlert feedback={shareFormFeedback} className="mt-2 mb-0" />
    </ComponentCard>
  );
}
