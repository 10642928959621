import { useCallback, useEffect, useState } from "react";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import axios from "axios";

//from securefilesharing project
const clientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;
const apiKey = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_SECRET;

// scope of the project
const SCOPE = [
  "https://www.googleapis.com/auth/drive.install",
  "https://www.googleapis.com/auth/drive.file",
  "https://www.googleapis.com/auth/drive.readonly",
  "https://www.googleapis.com/auth/drive.appdata",
  "https://www.googleapis.com/auth/gmail.send",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/drive",
].join(" ");

const discoveryUrl =
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";

const serverUrl = process.env.REACT_APP_SERVER_URL;
// listen for firebase auth changes
getAuth().onAuthStateChanged((user) => {
  if (user) {
    axios
      .post(`${serverUrl}generateKey`, {
        userExists: true,
        userEmail: user.email,
        pbValidVecBase64: null,
      })
      .then((res) => {console.log("key gen res" + res)})
      .catch((err) => {console.log("key gen error" + err)});
  } else {
  }
});

// adapted from oauth.js
export default function useGoogleAuth() {
  const [isAuthorizing, setIsAuthorizing] = useState(true);
  const [user, setUser] = useState(null);
  const [access_token, setAccess_Token] = useState(null);

  // initialize client: This all runs once on mount.
  useEffect(() => {
    // initialize firebase client
    const gapi = window.gapi;
    gapi.load("client:auth2", () => {
      gapi.client.load("gmail", "v1", () => {
        gapi.client
          .init({
            apiKey: apiKey,
            clientId: clientId,
            discoveryDocs: [discoveryUrl],
            scope: SCOPE,
            plugin_name: "Cynorix-GoogleDrive-FileShare",
          })
          .then(() => {
            const auth = gapi.auth2.getAuthInstance();

            handleSigninStatus(auth);

            // Listen for gapi sign-in state changes.
            auth.isSignedIn.listen((signedIn) => {
              setIsAuthorizing(true);
              handleSigninStatus(auth, auth.isSignedIn.get());
            });
          });
      });
    });
  }, []);

  const handleSigninStatus = useCallback((googleAuth, signedIn) => {
    let user = googleAuth.currentUser.get();
    let isAuthorized = user.hasGrantedScopes(SCOPE);

    // if user is not authorized, they will not be signed in as well according
    // to API.

    if (isAuthorized) {
      // firebase
      const authResponse = user.getAuthResponse(true);
      const credential = GoogleAuthProvider.credential(
        authResponse.id_token,
        authResponse.access_token
      );
      setAccess_Token(authResponse.id_token)
      const firebaseAuth = getAuth();
      signInWithCredential(firebaseAuth, credential).catch((error) => {
        console.log("Sign in error");
      });

      // set states
      setUser({
        id: user.getBasicProfile().getId(),
        email: user.getBasicProfile().getEmail(),
        name: user.getBasicProfile().getName(),
        imageUrl: user.getBasicProfile().getImageUrl(),
      });
    } else {
      // sign out from firebase
      getAuth().signOut();
      // clear states
      setUser(null);
    }

    setIsAuthorizing(false);
  }, []);

  //signs in user and redirects to set password
  const handleRegistration = useCallback(() => {
    const GoogleAuth = window.gapi.auth2.getAuthInstance();
    GoogleAuth.signOut();
    GoogleAuth.signIn({
      prompt: "select_account",
      // ux_mode: "redirect",
      // redirect_uri: `${process.env.REACT_APP_FRONTEND_URL}set-password`,
    });
  }, []);

  //signs in user
  const handleAuthClick = useCallback(() => {
    const GoogleAuth = window.gapi.auth2.getAuthInstance();
    GoogleAuth.signOut();
    GoogleAuth.signIn({ prompt: "select_account" });
  }, []);

  const signOut = () => {
    getAuth().signOut();
    let auth = window.gapi.auth2.getAuthInstance();
    auth.signOut();
  };

  return [user, isAuthorizing, handleAuthClick, handleRegistration, signOut,access_token];
}
