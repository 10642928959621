import React from 'react';
import { Modal, Button } from 'react-bootstrap'

export default function TextModal(props) {

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.handleClose}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
