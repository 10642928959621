import React, { Fragment, useState, useEffect } from "react";
import { Col, Container, Form, Row, Button, Modal } from "react-bootstrap";
import background from "../../assets/images/background9.png";
import "bootstrap/dist/css/bootstrap.min.css";
import ManageAccountOptsPage from "./ManageAccountOptsPage";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FeedbackAlert from "../FeedbackAlert";
import "./ChangeQuestionsAndAnswers.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PasswordWrapper from "../PasswordWrapper";
library.add(faEye, faEyeSlash);
const CryptoJS = require("crypto-js");
const AESLOOPS = 16384;

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ChangeQuestionsAndAnswers(props) {
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    questions: ["q1", "q2", "q3", "q4", "q5"],
    answers: ["", "", "", "", ""],
    password: "",
    code: "",
  });
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    if (props.user && !loading) {
      axios
        .post(SERVER_URL + "getQuestions", {
          userId: props.user.id,
        },{
          headers: {
            Authorization: `Bearer ${props.access_token}`,
          }
        })
        .then((res) => {
          if (res.data.success) {
            setFormValue({
              questions: res.data.data,
              answers: formValue.answers,
              password: formValue.password,
            });
            if (props.setQuestions) {
              props.setQuestions(res.data.data);
              setLoading(true);
            }
          } else {
            alert("Please set up your security questions first!");
            navigate("/change-questions-and-answers");
          }
        });
    }
  }, [props]);
  const [loading, setLoading] = useState(false);

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();

    setFeedback({
      loading: true,
      message: "Submitting security questions...",
      variant: "info",
    });

    const wildCharString = (len) => {
      var output = "";
      for (var i = 0; i < len; i++) {
        var rand = getRndInteger(0, 255);
        if (rand !== 124) {
          output += String.fromCharCode(rand);
        } else {
          output += String.fromCharCode(255);
        }
      }
      return output;
    };

    const getRndInteger = (min, max) => {
      return Math.floor(Math.random() * (max - min)) + min;
    };

    const uint8ToUint8Array = (str) => {
      let output = [];
      for (const letter of str) {
        output.push(letter.charCodeAt(0));
      }
      return output;
    };

    const XOR = (a, b) => {
      var result = new Uint8Array(a.length);
      for (let i = 0; i < a.length; i++) {
        result[i] = a[i] ^ b[i];
      }
      return result;
    };

    const uint8ArrayToUint8 = (array) => {
      var output = "";
      for (const element of array) {
        output += String.fromCharCode(element);
      }
      return output;
    };

    const strToBin = (str) => {
      var output = "";
      for (const element of str) {
        output += element.charCodeAt(0).toString(2).padStart(8, "0");
      }
      return output;
    };

    const reverseBits = (str) => {
      let bits = strToBin(str).split("").reverse().join("");
      let output = "";
      for (let i = 0; i < bits.length / 8; i++) {
        output += String.fromCharCode(
          parseInt(bits.substring(i * 8, (i + 1) * 8), 2)
        );
      }
      return output;
    };

    const generateKey = (answers) => {
      // Split into vectors of 512 bits (64 characters of 8 bits each)
      let AnswersVectors = answers.match(/.{1,64}/g);

      // Pad last vector with zeros
      AnswersVectors[AnswersVectors.length - 1] = AnswersVectors[
        AnswersVectors.length - 1
      ].padStart(64, "\u0000");

      // Convert strings to Uint8 Arrays to xor
      let Uint8ArrayAnswers = [];
      for (const element of AnswersVectors) {
        Uint8ArrayAnswers.push(uint8ToUint8Array(element));
      }

      // Xor all the vectors
      let xorResult = Uint8ArrayAnswers[0];
      for (var i = 1; i < Uint8ArrayAnswers.length; i++) {
        xorResult = XOR(xorResult, Uint8ArrayAnswers[i]);
      }

      // Convert to Uint8 String to convert to word array
      let xorResultUint8 = uint8ArrayToUint8(xorResult);

      let vector = xorResultUint8;
      let iv = CryptoJS.enc.Base64.parse("9De0DgMTCDFGNokdEEial");

      for (i = 0; i < AESLOOPS; i++) {
        // Create word array for the message and key
        let wordArray = CryptoJS.enc.Latin1.parse(reverseBits(vector));
        let key = CryptoJS.enc.Latin1.parse(vector);

        // Encrypt using AES-256-CBC
        // Only take the first 128 digits to get 512 bits
        vector = CryptoJS.AES.encrypt(wordArray, key, { iv: iv })
          .ciphertext.toString()
          .substring(0, 128);
      }

      // vector is 512 bits and is currently in hex
      return vector;
    };

    for (let i = 0; i < formValue.questions.length; i++) {
      for (let j = i + 1; j < formValue.questions.length; j++) {
        if (formValue.questions[i] === formValue.questions[j]) {
          setFeedback({
            variant: "warning",
            message: "Duplicate questions are not allowed.",
          });
          return;
        }
      }
    }

    let answer = "";
    formValue.answers.forEach((item) => {
      answer += item;
    });
    let key = generateKey(answer);
    // String of 64 characters, the password, two pipes, then the rest are wild characters
    let password = formValue.password;
    let paddedPassword = wildCharString(62 - password.length);
    const rand = getRndInteger(0, 63 - password.length);
    paddedPassword =
      paddedPassword.substring(0, rand) +
      "|" +
      password +
      "|" +
      paddedPassword.substring(rand);

    const wordArrayPassword = CryptoJS.enc.Utf8.parse(paddedPassword);
    const wordArrayKey = CryptoJS.enc.Hex.parse(key);
    const iv = CryptoJS.enc.Base64.parse("9De0DgMTCDFGNokdEEial");
    const encryptedPassword = CryptoJS.AES.encrypt(
      wordArrayPassword,
      wordArrayKey,
      { iv: iv }
    );

    axios
      .post(
        SERVER_URL + "submitQuestions",
        JSON.stringify({
          userId: props.user.id,
          questions: formValue.questions,
          password: encryptedPassword.toString(),
          verification: CryptoJS.enc.Hex.stringify(
            CryptoJS.SHA256(formValue.answers.join(""))
          ).substring(0, 8),
        }),
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        if (res.status === 200) {
          setFeedback({
            loading: true,
            message: "Security Questions updated successfully, redirecting...",
            variant: "success",
          });
          setTimeout(() => {
            navigate("/new");
          }, 2500);
        } else {
          setFeedback({
            message: "Something went wrong.",
            variant: "danger",
          });
        }
      })
      .catch((error) => {
        if (error.response.data.errormsg === "Wrong OTP") {
          setFeedback({
            variant: "warning",
            message: `Wrong OTP`,
          });
        }
      });
  };

  const changeBody = (
    <Fragment>
      <Form
        onSubmit={handleQuestionSubmit}
        className="align-items-center"
        style={{ width: "100%", display: "contents" }}
      >
        <Container style={{ paddingLeft: "0px" }}>
          {formValue.questions.map((question, index) => (
            <Form.Group className="mb-3">
              <Row className="mb-2">
                <Col
                  xs={12}
                  lg={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                  className={`mb-2 mb-lg-0 
                                justify-content-center justify-content-lg-end`}
                >
                  Security Question
                </Col>
                <Col xs={12} lg={10}>
                  <Form.Control
                    type="text"
                    required
                    key={index}
                    value={formValue.questions[index]}
                    onChange={(event) => {
                      let newQuestions = formValue.questions;
                      newQuestions[index] = event.target.value;
                      setFormValue({
                        questions: newQuestions,
                        answers: formValue.answers,
                        password: formValue.password,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  lg={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                  className="mb-2 mb-lg-0 justify-content-center justify-content-lg-end"
                >
                  Answer
                </Col>
                <Col xs={12} lg={10}>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    key={index}
                    required
                    value={formValue.answers[index]}
                    onChange={(event) => {
                      let newAnswers = formValue.answers;
                      newAnswers[index] = event.target.value;
                      setFormValue({
                        questions: formValue.questions,
                        answers: newAnswers,
                        password: formValue.password,
                        code: formValue.code,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>
          ))}
        </Container>
        <Button
          type="submit"
          className="mt-4 w-100"
          disabled={feedback && feedback.loading}
        >
          Submit
        </Button>
        <FeedbackAlert feedback={feedback} className="mb-0 mt-3" />
      </Form>
    </Fragment>
  );

  return (
    <>
      <ManageAccountOptsPage
        title={"Change Security Questions"}
        background={background}
        body={
          <PasswordWrapper
            user={props.user}
            access_token={props.access_token}
            requiresOTP
            setPassword={(password) => {
              setFormValue({
                ...formValue,
                password,
              });
            }}
          >
            {changeBody}
          </PasswordWrapper>
        }
        width="1000px"
      />
    </>
  );
}
